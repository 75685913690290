import { Injectable, TemplateRef } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpRequest, HttpParams } from '@angular/common/http';
import { RestResponse } from './restResponse';
import { AuthResponse } from './authresponse';
import { AuthLoginInfo } from './login-info';
import { SignUpInfo } from './signup-info';
import { User } from '../../model/user';
import { RegistrationRequest } from '../../model/RegistrationRequest';
import { ResponseData } from '../../model/ResponseData';
import { TokenStorageService } from './token-storage.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  toasts: any[] = [];
  private url = environment.GATEWAY_API_URL;

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) { }

  // Enregistrement d'un utilisateur
  /*register(signupInfo: SignUpInfo): Observable<string> {
    return this.http.post<string>(this.url + "/account/register", signupInfo, httpOptions);
  }*/
  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
  createUser(users: User): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.url + '/account/register', users, httpOptions);
  }

  createUserV2(users: RegistrationRequest): Observable<ResponseData> {
    return this.http.post<ResponseData>(this.url + '/account/register2', users, httpOptions);
  }


  verifyEmail(token: string): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.url + "/auth/verify/email?token=" + token, httpOptions);
  }

  // Connexion d'un utilisateur
  login(username: string, password: string): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.url + "/auth/login", { username, password }, httpOptions);

  }
  //connexion utilisant les promesses 
  loginPromise(userName: string, password: string): Promise<RestResponse> {
    //const promise = New Promise()
    return this.http.post<RestResponse>(this.url + "/auth/login", { userName, password }, httpOptions).toPromise();
  }

  // Premiere Connexion d'un utilisateur
  verifyActivation(token: string, pass: string): Observable<RestResponse> {
    let params = new HttpParams();

    params = params.append('pass', pass);
    params = params.append('token', token);
    return this.http.get<RestResponse>(this.url + "/auth/login/verifyActivation", {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params
    });
  }

  resetPassword(password: string, passwordConfirmation: string, token: string): Observable<any> {
    return this.http.post<any>(this.url + "/auth/reset/password", { password, passwordConfirmation, token });
  }

  // Lancement de la réinitialisation du mot de passe
  forgotPassword(email: string): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.url + "/auth/forgot/password", { email }, httpOptions);
  }


  // Récupération de l'utilisateur courant
  getCurrentUser(): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.url + "/auth/me", httpOptions);
  }
  getUserRole() {
    return this.tokenStorageService.getUserInfos().authorities[0]
  }
  /**
   * // Enregistrement d'un employé
  register(registerRequest: RegisterRequest): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.url + "/auth/register", JSON.stringify(registerRequest), httpOptions);
  }





  // Récupération de l'utilisateur courant
  getCurrentUser(): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.url + "/user/me", httpOptions);
  }




   */

}
