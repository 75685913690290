import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpRequest, HttpParams } from '@angular/common/http';
import {Observable}  from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OfferHandleService {
  jtn = localStorage.getItem('access');
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.jtn
    })}
  constructor(private http:HttpClient) { }


  savedOffer(idUser,idOffer)
  {
      return  this.http.post(environment.serverEndpoint+'/company/offer/savedOffer?offerId='+idOffer+'&userId='+idUser,this.httpOption);
  }
  removeSavedOffer(idUser,idOffer)
  {
      return  this.http.post(environment.serverEndpoint+'/company/offer/removeSavedOffer?offerId='+idOffer+'&userId='+idUser,this.httpOption);
  }
//https://company.ushareandwin.com/company/offer/savedOffer?offerId=5fc2e48505f2094faad7121b&userId=5e7a2beabffcca03f8e5578c
//https://company.ushareandwin.com/company/view/createShare?flag=SHARE&idOffer=612392b4d5e74c61ad895438&idUser=5e7a2beabffcca03f8e5578c

  reportOffer(idUser,idOffer)
  {
     return this.http.get(environment.serverEndpoint+'/company/offer/reportedOffer?offerId='+idOffer+'&userId='+idUser,this.httpOption)
  }
  listOffer(idUser)
  {
     return this.http.get(environment.serverEndpoint+'/company/offer/listOfSavedOffer?userId='+idUser,this.httpOption)
  }
}
