import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
  } from "@angular/common/http"
  import { Observable } from "rxjs"
  
  export class JWTInterceptor implements HttpInterceptor {
    constructor()
    {}
    intercept(
      request: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let token = localStorage.getItem('accessToken');
        if (token) {
           // alert(token)
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return next.handle(request);
    }
  }