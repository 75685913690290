import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpRequest, HttpParams } from '@angular/common/http';
import {Observable, BehaviorSubject, from}  from 'rxjs';

import { environment } from 'src/environments/environment';
import {ResponseData} from '../model/ResponseData';
@Injectable({
  providedIn: 'root'
})
export class ShareService {
 ;
  jtn = localStorage.getItem('access');

  state = new BehaviorSubject <any>([]);
  id = new BehaviorSubject<string>('');
  myDate : string;
  email:string[]=[]
  message:any
  socketReply = new BehaviorSubject <any>({});
  currentSocketReply = this.socketReply.asObservable();
  currentdata= from(this.state.asObservable())
  currentid=this.id.asObservable();
  //current=this.state.asObservable()
  constructor(private http:HttpClient) { }

    getSocketReply (array){

         this.socketReply.next(array);

     }

  getChange(array)
  {
    this.state.next(array)
  }
  getTheid(id)
  {
   // //alert(id)
     this.id.next(id)
  }

  getLink(id:any)
  {
    return this.http.get(environment.serverEndpoint+'/share/get-link?offerId='+id, );
  }

  shareOffer(shareId,media)
  {
    return this.http.post(environment.serverEndpoint+'/share?shareId='+shareId,media,);
  }

  shareOfferWithoutShare(shareId,media)
  {
    return this.http.post(environment.serverEndpoint+'/share/empty?shareId='+shareId,media,);
  }

 /*  shareOfferByMail(shareId)
  {
    return this.http.post(environment.serverEndpoint+'/share/byMail?shareId='+shareId,{this.shareViaMailDto},);
  } */

  shareOfferByMail(shareId,shareViaMailDto): Observable<ResponseData> {



    // this.email.concat(shareViaMailDto.emailList)
     if( shareViaMailDto.emailList.length>0){
      for (let index = 0; index < shareViaMailDto.emailList.length; index++) {
        this.email.push(shareViaMailDto.emailList[index].email)

     }
     console.log('this is the email  send '+this.email);
     shareViaMailDto.emailList = this.email;
  //   console.log("++++" + shareViaMailDto);

    return this.http.post<ResponseData>(environment.serverEndpoint+'/share/byMail?shareId='+shareId,
    shareViaMailDto, );

     }

     alert("do not forget to insert an email address");

   }

   registerShare(shareToken:any): Observable<ResponseData>
   {

      return this.http.get<ResponseData>(environment.serverEndpoint+'/share/register/'+shareToken);
   }

   getAsingleOffer(shareToken:any): Observable<ResponseData>
   {

      return this.http.get<ResponseData>(environment.serverEndpoint+'/company/offer/myShareTokenOffer?shareToken='+shareToken)
      //https://backend.ushareandwin.com              /company/offer/myShareTokenOffer?shareToken=6239bb6b60211309165da01d@2022-03-22T23:28:33Z
   }


}
