import { Injectable, EventEmitter } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { Signal } from "../model/signal";
import { TokenStorageService } from "src/app/auth/helpers/token-storage.service"
@Injectable({
  providedIn: 'root'
})
export class MessessageSignalService {
  private hubConnection: signalR.HubConnection;
  signalReceived = new EventEmitter<Signal>();

  constructor(private userService: TokenStorageService) {
    this.buildConnection();
    this.startConnection();
  }

  private buildConnection() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(
        "https://notification.ushareandwin.com/chathub" /* ,{
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      } */
      ) //use your api adress here and make sure you use right hub name.
      .build();
  }

  private startConnection() {
    this.hubConnection
      .start()
      .then(() => {
        let conversation: any[] = JSON.parse(localStorage.getItem('acceptedRequest'));
        console.log("Connection Started...");
        console.log("join group");


        if (conversation != null) {
          conversation.forEach(element => {
            this.joinGroup(element.conversationId);
          });
        }



        this.registerSignalEvents();
      })
      .catch((err) => {
        console.log("Error while starting connection: " + err);
        let that = this;
        //if you get error try to start connection again after 3 seconds.
        setTimeout(function () {
          that.startConnection();
        }, 3000);
      });
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////
  ///     //////////////////////////////////////////////////////////////////////////////       ///
  //////////                                                                          ////////////
  private joinGroup(referenceId: string) {


    console.log("connection to : " + referenceId);
    try {
      this.hubConnection.invoke("JoinConversation", referenceId);
      console.log("connected to referenceId ", referenceId);
    } catch (error) {
      console.log(error);
      location.reload()
    }
  }

  private registerSignalEvents() {
    this.hubConnection.on("ReceiveMessage", (data: Signal) => {
      console.log(data);

      this.signalReceived.emit(data);
    });
  }
}

