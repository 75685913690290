import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TokenStorageService } from '../../auth/helpers/token-storage.service';
import { Router } from '@angular/router';
import { User } from '../../model/user';
import { Notifications } from '../../model/Notifications';
import { UserService } from '../../services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateAccount } from '../../model/UpdateAccount';
import { LoyaltyPoint } from '../../model/LoyaltyPoint';
import { environment } from 'src/environments/environment';
import { ToastService } from '../../services/toast-service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { RestResponse } from '../../model/RestResponse';
import { FileUploadService } from '../../services/file-upload.service';
import { Offer } from '../../model/Offer';
import { CompanyService } from '../../services/company.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { ToastrService } from 'ngx-toastr';
import { ShareService } from 'src/app/services/share.service';
import { Pagination } from '../../model/Pagination';
import { Pageable } from '../../model/Pageable';
import { PageableCount } from '../../model/PageableCount';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResponseData } from '../../model/ResponseData';
import { Recherche } from 'src/app/model/recherche';
import { Group } from '../../model/Group';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import * as $ from 'jquery';
import { SignalRService } from 'src/app/services/signal-r.service';
import { Signal } from 'src/app/model/signal';
import * as internal from 'assert';
import { PartnerServiceService } from 'src/app/services/partner-service.service';
import { GroupesignalService } from 'src/app/services/groupesignal.service';

@Component({
  selector: 'app-header-partner',
  templateUrl: './header-partner.component.html',
  styleUrls: ['./header-partner.component.css']
})
export class HeaderPartnerComponent implements OnInit {

  recherche = new Recherche();
  @Output() event = new EventEmitter<Recherche>();
  @Output() even = new EventEmitter<any>();
  @Output() accept = new EventEmitter<any>();
  @Output() partnerResult = new EventEmitter<any>();
  flag: any;
  // Déclaration d'une liste de posts
  private posts: any[] = [];
  stClient: any;
  activityN: any;
  partnerN: any;
  acceptN: any;
  selectedFiles: FileList;
  currentFile: File;
  progress = 0;
  isUserProfilPic : boolean = true;
  notif_group = 0;
  notif_activity = 0;
  notif_general = 0;
  notif_chat = 0;
  message = '';
  isProfilePhote: boolean = true;
  firstName: string;
  lastName: string;
  loyaltyPoint: LoyaltyPoint;
  email: string;
  address: string;
  userIden: string;
  closeResult: string;
  displayUserInfo: boolean;
  displayUserContact: boolean;
  count: any = 0;
  notifsImg = environment.GATEWAY_API_URL + '/account/read/';
  displayBasicInfo: boolean;
  displayName: boolean;
  displayLastname: boolean;
  displayAddress: boolean;
  actionMessage: string;
  updateAccountForm: FormGroup;
  private currentUser: any;
  uploadForm: FormGroup;
  user: User = new User();
  userAccount: UpdateAccount = new UpdateAccount();
  submitted = false;
  isLoggedIn: boolean;
  userId: string;
  identifiant: string;
  //notifications:any;
  notifications: any[]=[];
  notificationsG: any[]=[];
  receiver: any;
  notificationsUnseen: any;
  stCliente: any;
  activityNotification: any;
  partnerNotification: any;
  acceptNotifications: any;
  loggInUser: User;
  url: string = environment.GATEWAY_API_URL;
  photoProfil: string;
  restResponse: RestResponse;
  selectedFile: File = null;
  preview: string;
  pageno = 0;
  companyId: string;

  form: FormGroup;
  offersRawInfo: any;
  offers: Offer[] = [];
  pagesNumber: number;
  selectPageSize: number[];
  numberOfPages: number;
  paginationInfo: Pagination;
  offerNumber: any;

  /*** Pageable Information ****/
  private pageableItem: Pageable = {
    page: 0,
    size: PageableCount.SIZE,
  };
  name: string;
  show:boolean=false;
  pageItem = this.pageableItem.page;
  itemsPageItem = this.pageableItem.size;
  totalItems = this.pageableItem.size;
  groupsRawInfo: any;
  groups: Group[] = [];
  datum: any;
  users: any[] = [];
  index = 0;
  role:any;
  requestPatnership: any[] = [];
  acceptedRequest: any[] = [];
  usersNext: any[] = [];
  footer: boolean;
  partnershipId: string;
  partnerCount: number;
  generalNotification : Number;
  companyFilterOfferUrl = environment.COMPANY_BASEURL + '/company/filterOffer';
  companyFilterGroupUrl = environment.COMPANY_BASEURL + '/company/filterGroup';
  signalList: Signal[] = [];
  private pendingPartnersCount: number;
  constructor(
    private translate: TranslateService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private userService: UserService,
    private webService: WebSocketService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private toastService: ToastService,
    private share: ShareService,
    // tslint:disable-next-line:max-line-length
    protected fileUploadService: FileUploadService,
    private token: TokenStorageService,
    private companyService: CompanyService,
    private route: Router,
    private signalService: SignalRService,
    private partnerServeice: PartnerServiceService,
    private groupeSignalService : GroupesignalService
    
  ) {
    translate.setDefaultLang('en');
   }

  public ngOnInit() {
    this.groupeSignalService.signalReceived.subscribe((signal:Signal)=>{
      document.getElementById('show').style.display='block';
      document.getElementById('bells').classList.add('bell')
      console.log('__________________');
      console.log(signal);
      console.log('__________________');
      this.groups.push(signal.notificationModel); 
      this.notif_group = this.groups.length;
      document.getElementById('groupCount').innerHTML= this.notif_group+'';  
      this.notificationsUnreadGeneral();  
      this.generalNotification = signal.notificationModel.generalNotification;
      this.signalList.push(signal);
      
    });
    this.signalService.signalReceived.subscribe((signal: Signal) => {
      document.getElementById('show').style.display='block';
      document.getElementById('bells').classList.add('bell')
      console.log('__________________');
      console.log(signal);
      console.log('__________________');
      let s:any
      this.notifications.push(signal.notificationModel);    
      this.notificationsUnreadGeneral();  
      this.generalNotification = signal.notificationModel.generalNotification;
      this.signalList.push(signal);
      
    });
   /*  let role :any;
    if(this.token.getUserInfos().user)
    {
      role = this.token.getUserInfos().user.authorities;
    }else{
      role=this.token.getUserInfos().authorities
    }
    console.log("-----------------------------vip----------");

    console.log(role);
    if (role.length == 2) {
        if (role[1].length=='ADMIN') {
          this.route.navigate(['/admin/admin-home'])
        } else {
          this.route.navigate(['/company/company-home'])
        }
    }  */
      $(document).ready(() => {
        let down = false;

        $('#bell').on('click', (e) => {
          const color = $(this).text();
          if (down) {
            $('#box').css({ height: '0px', opacity: '0', display: 'none' });
            down = false;
          } else {
            $('#box').css({ height: '480px', opacity: '1', display: 'block', 'overflow-y': 'scroll' });
            down = true;
          }
        });
      });

      this.init();
      this.getCurrentUser();
      this.userId = this.token.getUserInfos().id;
      ////alert(this.userId)
      this.getOffers(10, this.pageno, 'id');
     //-- this.chatNotification(this.userId);
      //--this.groupToNotification(this.userId);
      //--this.activityToNotification(this.userId);
      //--this.partnerToNotification(this.userId);
     //-- this.acceptNotification(this.userId);
      //this.connectToNotification();
      this.notificationsUnread();
      this.unseenNotificationLists();
      this.unseenNotificationLists2();
      this.notificationsUnreadGeneral(); 

    // //alert('header')
  }

  getAllPendingDemands() {
    this.userService.patnerShip(this.tokenStorage.getUserInfos().id).subscribe(
      (data: any) => {
        this.users = data.data;
      },

      (error) => {
        this.toastr.success('Internal server error');
      }
    );
  }
  getAllPendingDemand() {
    this.userService
      .getPaginationPartnership(
        this.tokenStorage.getUserInfos().id,
        10,
        0,
        'id'
      )
      .subscribe((data: any) => {
        // console.log(data.data);
        this.requestPatnership = data.data.content;
      });
  }
  getAllAcceptedDemand() {
    this.userService
      .getAllAcceptedPartner(this.tokenStorage.getUserInfos().id)
      .subscribe((data: any) => {
        /*  console.log('demandes acceptées');
                 console.log(data.data); */
        this.acceptedRequest = data.data;
      });
  }

  public displayPostAvatarMiniature(uid: string): string {
    return this.url + '/account/readMiniature/' + uid;
  }

  cancelPartnership(idPartner) {
    this.userService
      .removePartners(this.tokenStorage.getUserInfos().id, idPartner)
      .subscribe(
        (data: any) => {
          // //alert(data.data)
          if (data.data == true) {
            this.toastr.success('Pending PatnerShip Cancelled');
          }
          this.ngOnInit();
        },
        (error) => {
          this.toastr.error('Internal server error');
        }
      );
  }

 /*  groupToNotification(identifiant: string) {
    if (this.isLoggedIn) {
      console.log('receive notification for offers...');
      const serverUrl = environment.GATEWAY_API_UR;
      const ws = new SockJS(serverUrl + '/chat');
      this.stCliente = Stomp.over(ws);
      const that = this;
      this.identifiant = this.token.getUserInfos().id;

      this.stCliente.connect({}, (frame) => {
        that.stCliente.subscribe('/topic/groupNotif/' + that.identifiant,
          (response) => {
            const datum = response.data as Notifications;

            const data = JSON.parse(response.body);
            console.log(data.groupNotification);
            document.getElementById('groupCount').innerHTML = '' + data.groupNotification;
            document.getElementById('generalNotifCount').innerHTML = '' + data.groupNotification;
            $('#groupCount').trigger('refresh');
            $('#generalNotifCount').trigger('refresh');
          }
        );
      },
        (error) => {
          this.toastr.error('Cannot Conect to the Socket');
        }
      );

    }
  } */

  /***Accepted Notification */
 /*  acceptNotification(identifiant: any) {
    if (this.isLoggedIn) {
      //  this.spinner.show()
      console.log('receive notification for parnership request...');
      const serverUrl = environment.GATEWAY_API_UR;
      const ws = new SockJS(serverUrl + '/chat');
      this.acceptNotifications = Stomp.over(ws);
      const that = this;
      this.identifiant = this.token.getUserInfos().id;

      this.acceptNotifications.connect({}, (frame) => {
        // //alert(identifiant)
        // that.spinner.hide();
        that.acceptNotifications.subscribe(
          '/topic/partnerNotifOk/' + that.identifiant,
          (response) => {
            // this.datum = response.data;
            console.log(response.body);
            const data = JSON.parse(response.body);

            // //alert(data.partnersNumber);
            document.getElementById('partnerCount').innerHTML = '' + data.partnersNumber;
            that.accept.emit(data);
          }
        );
      },
        (error) => {
          this.toastr.error('Cannot Conect to the Socket');
        }
      );
    }
  }


 */
 /*  partnerToNotification(identifiant: string) {
    if (this.isLoggedIn) {
      console.log('receive notification for parnership request...');
      const serverUrl = environment.GATEWAY_API_UR;
      const ws = new SockJS(serverUrl + '/chat');
      this.partnerNotification = Stomp.over(ws);
      const that = this;
      this.identifiant = this.token.getUserInfos().id;

      this.partnerNotification.connect({}, (frame) => {
        // //alert(identifiant)

        that.partnerNotification.subscribe('/topic/partnerNotif/' + that.identifiant,
          (response) => {
            this.datum = response.data;
            console.log(response.body);
            const data = JSON.parse(response.body);
            if (data) {
              //this.getAllPendingDemands();
            //this.getAllPendingDemand();
           // this.getAllAcceptedDemand();
              if (data.partnersNumber > 0) {
                this.partnerCount = data.partnersNumber;
              }
              if (data.pendingPartnersNumber > 0) {
                this.pendingPartnersCount = data.pendingPartnersNumber;
              }
              document.getElementById('partnerCount').innerHTML = '' + data.partnersNumber;
              that.even.emit(data);
              //  document.getElementById("pendingPartnersCount").innerHTML=""+data.pendingPartnersNumber;
            }

            // let data = JSON.parse(response.body);
            // console.log(data);
            // console.log(this.datum);
            //  console.log(data);

            // document.getElementById("generalNotifCount").innerHTML=""+data.groupNotification;
          }
        );
      },
        (error) => {
          this.toastr.error('Cannot Conect to the Socket');
        }
      );
      // console.log(this.count);
    }
  } */

 /*  activityToNotification(identifiant: string) {
    if (this.isLoggedIn) {
      console.log('receive notification for activities...');
      const serverUrl = environment.GATEWAY_API_UR;
      const ws = new SockJS(serverUrl + '/chat');
      this.acceptNotifications = Stomp.over(ws);
      const that = this;
      this.identifiant = this.token.getUserInfos().id;
      // //alert(this.userId);
      // tslint:disable-next-line:only-arrow-functions
      this.acceptNotifications.connect({}, (frame) => {
        // //alert(name);
        // //alert(this.token.getUserInfos().id);
        that.acceptNotifications.subscribe('/topic/activityNotif/' + that.identifiant,
          (response) => {
            // //alert("read notif");
            // //alert(this.userId);
            const datum = response.data as Notifications;
            const data = JSON.parse(response.body);
            // console.log("----->"+data.activitiesNotification);
            //  console.log(data);
            // console.log(data.activitiesNotification);
            document.getElementById('activityCount').innerHTML = '' + data.activitiesNotification;
            document.getElementById('generalNotifCount').innerHTML = '' + data.groupNotification;
          }
        );
      });
      // console.log(this.count);
    }
  } */

  public displayPostAvatar(uid: string): string {
    return this.url + '/account/read/' + uid;
  }

  public displayPostAvatarMedium(uid: string): string {
    return this.url + '/account/readMedium/' + uid;
  }

  public init(): void {
    // prmiere alternative pour resourdre le probleme de header lorsqu'on se connecte via réseaux sociaux

    // console.log("tokenUser----> "+this.token.getUserInfos());
    if (localStorage.getItem('social') === 'yes') {
      this.isLoggedIn = true;
      const isSuccess = this.getCurrentUserOAuth();
      if (isSuccess) {
        console.log(this.token);
      }
    }
    if (this.token.getUserInfos()) {
      // //alert('-------------------olllrllrlrlrl')
      this.currentUser = this.token.getUserInfos();
      console.log('this------------------');
      console.log(this.currentUser);
      console.log('this------------------end');
      console.log(this.isLoggedIn);
      // this.count = this.currentUser.groupNotification
      if (this.currentUser.groupNotifications > 0) {
        document.getElementById('groupCount').innerHTML = ' ' + this.currentUser.groupNotification;
        document.getElementById('activityCount').innerHTML = ' ' + this.currentUser.activitiesNotification;
      }

      if (this.currentUser.photo) {
        this.isProfilePhote = true;

        alert("1")

        this.photoProfil =
          this.url + '/account/readMiniature/' + this.currentUser.photo.uid;
        console.log(this.photoProfil);
        //  //alert("ok tof")
      }

      this.isLoggedIn = true;
    }
  }

  notificationsUnreadToRead(id) {
    this.webService
      .unseenToSeen(this.token.getUserInfos().id, id)
      .subscribe((data) => {
        this.notificationsUnread();
        this.route.navigate(['/user/group']);
      });
     this.notificationsUnreadGeneral(); 
  }

  // On retourne toute la liste des notifications du user de la plus jeune à la plus vieille notif
  notificationsUnread() {
    if (this.isLoggedIn) {
      // 'GROUP' doit etre fixé dès le html
      this.webService
        .notificationList('GROUP', this.token.getUserInfos().id)
        .subscribe((data:any) => {
          // //alert("ok")
         // console.log(data.data);
          this.notificationsG = data.data;
        });
    }
  }

   notificationsUnreadGeneral() {
  
    if (this.isLoggedIn) {
      // 'GROUP' doit etre fixé dès le html
      this.webService
        .notificationList('GENERAL', this.token.getUserInfos().id)
        .subscribe((data:any) => {
          // //alert("ok")
         // console.log(data.data);
          this.notifications = data.data;
        });
    }
    
  } 
  unseenNotificationLists() {
    // liste des notifications de group "non vues" par le user
    if (this.isLoggedIn) {
      // 'GROUP' doit etre fixé dès le html
      this.webService
        .unseenNotificationLists(
          'UNSEEN',
          'GROUP',
          this.token.getUserInfos().id
        )
        .subscribe((data) => {
          // //alert("ok")
          console.log(data.data);
          this.notificationsUnseen = data.data;
          this.notif_group = this.notificationsUnseen.length;
          this.notif_general = this.token.getUserInfos().generalNotification;
        });
    }
  }

  unseenNotificationLists2() {
    // liste des notifications de group "non vues" par le user
    if (this.isLoggedIn) {
      // 'GROUP' doit etre fixé dès le html
      this.webService
        .unseenNotificationLists(
          'UNSEEN',
          'ACTIVITY',
          this.token.getUserInfos().id
        )
        .subscribe((data) => {
          // //alert("ok")
          console.log(data.data);
          this.notificationsUnseen = data.data;
          this.notif_activity = this.notificationsUnseen.length;
          this.notif_general = this.token.getUserInfos().generalNotification;
        });
    }
  }

  generalUnseenToSeen(type: string) {
    if (this.isLoggedIn) {
      this.webService
        .generalUnseenToSeen(type, this.token.getUserInfos().id)
        .subscribe((data:any) => {
         // console.log(data.data);
         // this.notifications = data.data;
          this.notif_group = data.data.groupNotification;
          this.notif_activity = this.currentUser.activitiesNotification;
          this.notif_general = data.data.generalNotification;
        });
      this.ngOnInit();
    }
  }

  getOffers(pagesize: number, pageno: number, sortby: string) {
    this.companyService.getOffers(pagesize, pageno, sortby).subscribe(
      (data) => {
        if (data) {
          if (data.data) {
            const response = data.data as RestResponse;
            if (response.content) {
              this.posts = response.content as Offer[];
            }

            //console.log('These are all the offers:  ', this.posts);

            this.flag = this.posts.length;
            // console.log(this.posts[0].avatar.uid);
          }
        }
      },
      (error) => { }
    );
  }


 /*  chatNotification(identifiant: any) {
    if (this.isLoggedIn) {
      //  this.spinner.show()
      console.log('receive notification for chat...');
      const serverUrl = environment.GATEWAY_API_UR;
      const ws = new SockJS(serverUrl + '/chat');
      this.acceptNotifications = Stomp.over(ws);
      const that = this;
      this.identifiant = this.token.getUserInfos().id;
      this.acceptNotifications.connect({}, (frame) => {
        that.acceptNotifications.subscribe(
          '/topic/messaging/' + that.identifiant,
          (response) => {
            console.log(response.body);
            const data = JSON.parse(response.body);
            console.log(data);
            //alert('ok');
            document.getElementById('chatCount').innerHTML = '' + data.number;
            that.accept.emit(data);
            if (data.isView) {
              //this.getMessage(this.tokenStorage.getUserInfos().id, data.sender);
              this.notif_chat = data.number;
              console.log("inside the if " + response.body);
              that.share.getSocketReply(data);
              //alert(data.number);
            }
          }
        );
      },
        (error) => {
          this.toastr.error('Cannot Conect to the Socket');
        }
      );
    }
  } */

  /*   connectToNotification() {
      console.log('receive notification...');
      const serverUrl = environment.GATEWAY_API_UR;
      const ws = new SockJS(serverUrl + '/chat');
      this.stClient = Stomp.over(ws);
      const that = this;
      // tslint:disable-next-line:only-arrow-functions
      this.stClient.connect({}, (frame) => {
        // //alert(name);
        // //alert("okookoko")
        that.stClient.subscribe('/topic/notification', (response) => {
          //   //alert("read notif");
          const data = JSON.parse(response.body);
          console.log(data.count);
          document.getElementById('count').innerHTML = '' + data.count;
        });
      });
      console.log(this.count);
    } */

  // this method allow us to permute languages
   switchLanguage(language: string) {
    this.translate.use(language);
  } 

  goToHome() {
    this.router.navigate(['/home']);
  }

  goTo(url: string) {
    this.router.navigate(['/user/' + url]);
  }

  goToAuth(url: string) {
    this.router.navigate(['/auth' + url]);
  }

  public logout(): void {
    this.userService.logout().subscribe(
      (data) => {
        console.log(data);
      },
      (error) => {
        console.log(error);
      }
    );
    this.router.navigate(['/auth/login']);
  }

  getCurrentUser() {
    this.userService.getMyAccount().subscribe(
      (data) => {
        const status = data.status;
        if (status === 'SUCCESS') {
          // L'opération s'est effectuée avec succès

          // récupérer la donnée
          const currentUser = data.data as User;
          // permmetre à l'objet recupéré être utilisé dans la vue (exemple : loggInUser.firstName)
          this.loggInUser = currentUser;

          console.log('this is the current user ------------ ' + currentUser);

          this.isLoggedIn = true;
          // this.marketing(this.loggInUser.id);
          if (this.loggInUser) {
            if (localStorage.getItem('social') === 'yes') {              
              this.isProfilePhote = true;
           
              this.photoProfil = this.loggInUser.imageUrl;
            } else if (localStorage.getItem('social') !== 'yes') {            
              this.isProfilePhote = true;             
              if(this.loggInUser.imageUrl===''){
                 this.isUserProfilPic = false;
               
              } else{
                this.isUserProfilPic = true;
              }
              
              this.photoProfil = this.url + '/account/readMiniature/' + this.loggInUser.imageUrl;
              
            }
          } else {
            this.isProfilePhote = false;
          
          }
        } else {
          // Un problème est survenue lors de l'opération

          this.actionMessage = data.message;
          // in case there is a problem, display the below message in a toast
          this.toastService.show('Impossible to load profil user!', {
            classname: 'bg-danger text-light',
            delay: 5000,
          });
        }
      },
      (error) => {
        // Une erreur est intervenue lors d l'opération

        this.actionMessage = 'An error occured while searching for projects, please try later';
        console.log(this.actionMessage);
      }
    );
  }

  _getHistory(request) {
    this.receiver = request;
    ////alert(this.tokenStorage.getUserInfos().id)
    ////alert(this.receiver.id)
    this.userService
      .turnUnseenToSeen(this.tokenStorage.getUserInfos().id, this.receiver.id)
      .subscribe((data) => {
        ////alert("seen")
        this.userService
          .getMyconversation(this.tokenStorage.getUserInfos().id, this.receiver.id)
          .subscribe(
            (data) => {
              console.log(data);
              let utilisateur: any[] = [];

              utilisateur.push(data);
              // //alert('555')
              utilisateur.push(this.receiver);
              this.share.getChange(utilisateur);
              // //alert('ok')
            },
            (error) => {
              this.toastr.error("Cannot get the message!");
            }
          );
      });

  }


  getMessage(receiverId: string, senderId: string) {

    this.userService
      .getMyconversation(receiverId, senderId)
      .subscribe(
        (data) => {
          console.log(data);
          let utilisateur: any[] = [];

          utilisateur.push(data);
          // //alert('555')
          utilisateur.push(this.receiver);
          this.share.getChange(utilisateur);
          // //alert('ok')
        },
        (error) => {
          this.toastr.error("Cannot get the message!");
        }
      );


  }

  getCurrentUserOAuth(): boolean {
    this.userService.getMyAccount().subscribe(
      (data) => {
        const status = data.status;
        if (status === 'SUCCESS') {
          // L'opération s'est effectuée avec succès

          // récupérer la donnée
          const currentUser = data.data as User;
          // permmetre à l'objet recupéré être utilisé dans la vue (exemple : loggInUser.firstName)
          this.loggInUser = currentUser;
          this.isLoggedIn = true;
          // enregistrement des informations du user dans le local Storage
          this.token.saveUserInformations(
            this.loggInUser.firstName,
            this.loggInUser.lastName,
            this.loggInUser.email,
            this.loggInUser.address
          );
          this.token.saveUserInfos(currentUser);
          if (localStorage.getItem('social') === 'yes') {
            this.isProfilePhote = true;
            //alert("5")
            this.photoProfil = this.loggInUser.imageUrl;
          } else if (localStorage.getItem('social') !== 'yes') {
            this.isProfilePhote = true;
            //alert("6")
            this.photoProfil =  this.url + '/account/readMiniature/' + this.loggInUser.imageUrl;
            console.log(this.photoProfil);
          } else {
            //alert("photo profil 3");
            this.isProfilePhote = false;
            //alert("7")
          }

          return true;
        }
        // Un problème est survenue lors de l'opération

        this.actionMessage = data.message;
        return false;
        // in case there is a problem, display the below message in a toast
        // this.toastService.show('Impossible to load profil user!', { classname: 'bg-danger text-light', delay: 5000 })
      },
      (error) => {
        // Une erreur est intervenue lors d l'opération
        this.actionMessage =
          'An error occured while searching for projects, please try later';
        console.log(this.actionMessage);
        return false;
      }
    );
    return false;
  }

  /**************** Search engine ******** */

  SearchEngine() {
    // //alert(this.name);
    if (this.name !== '') {
      this.spinner.show();
      this.companyService
        .filterPaginateEntities(
          this.name,
          this.pageableItem.size,
          0,
          this.companyFilterOfferUrl
        )
        .subscribe(
          (value: ResponseData) => {
            if (value.status === 'SUCCESS') {
              this.offersRawInfo = value.data as RestResponse;
              this.posts = this.offersRawInfo.content as Offer[];
              this.paginationInfo = value.data as Pagination;
              this.totalItems = this.paginationInfo.totalElement;
              this.recherche.IfFind = true;
              this.recherche.elementRechercher = this.posts;
              this.event.emit(this.recherche);
              this.spinner.hide();
            }
          },
          (error) => {
            console.log(error);
            this.spinner.hide();
          }
        );
    } else if (this.name === '') {
      this.recherche.IfFind = false;
      this.event.emit(this.recherche);
      this.ngOnInit();
    }
  }
  Search() {
    const components = localStorage.getItem('component');

    if (components === 'group' || components === 'activity') {
      this.SearchEngineGroup();
    }else if(components ==='partner'){
      this.searchPartner()
    } else {
      this.SearchEngine();
    }
  }
  SearchEngineGroup() {
    if (this.name !== '') {
      this.spinner.show();
      this.companyService
        .filterPaginateEntities(
          this.name,
          this.pageableItem.size,
          0,
          this.companyFilterGroupUrl
        )
        .subscribe(
          (value: ResponseData) => {
            if (value.status === 'SUCCESS') {
              this.groupsRawInfo = value.data as ResponseData;
              this.groups = this.groupsRawInfo.content as Group[];
              this.paginationInfo = value.data as Pagination;
              this.recherche.IfFind = true;
              this.recherche.elementRechercher = this.groups;
              this.event.emit(this.recherche);

              this.totalItems = this.paginationInfo.totalElement;
              this.spinner.hide();
            }
          },
          (error) => {
            console.log(error);
            this.spinner.hide();
          }
        );
    } else if (this.name === '') {
      this.recherche.IfFind = false;
      this.event.emit(this.recherche);
      this.ngOnInit();
    }
  }
  searchPartner()
  {

    if(this.name!='')
    {
       this.partnerServeice.filterPartner(this.name, this.tokenStorage.getUserInfos().id).subscribe((data:any)=>{
            this.recherche.IfFind=true;
            console.log('response');
            
           this.recherche.elementRechercher=data.data;
           this.partnerResult.emit(this.recherche)
            
       },error=>{
           this.toastr.error('cannot get partner')
       })
    }else if (this.name === '') {
      this.recherche.IfFind = false;
      this.partnerResult.emit(this.recherche);
      this.ngOnInit();
    }

  }

}
