import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/helpers/auth.guard';
import { Role } from './model/enumRole';

const routes: Routes = [
  { path: 'auth', loadChildren: './auth/auth.module#AuthModule' },
  { path: 'home', loadChildren: './home/home.module#HomeModule', canLoad: [AuthGuard] },
  { path: 'admin', loadChildren: './admin/admin.module#AdminModule', canActivate: [AuthGuard], data: { role: [Role.Admin] } },
  { path: 'company', loadChildren: './company/company.module#CompanyModule', canActivate: [AuthGuard], data: { role: [Role.Employe] } },
  { path: 'user', loadChildren: './user/user.module#UserModule', canActivate: [AuthGuard], data: { role: [Role.User] } },
  { path: 'general', loadChildren: './comonpage/comonpage.module#ComonpageModule' },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
