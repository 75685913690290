export const environment = {
   production: false,
   // GATEWAY_API_URL : 'http://localhost:8090',
   GATEWAY_API_UR : 'https://backend.ushareandwin.com',
   GATEWAY_API_URL : 'https://backend.ushareandwin.com',
   baseUrl: 'https://ushareandwin.com',
   serverEndpoint : 'https://backend.ushareandwin.com',
   // export const ACCESS_TOKEN = 'accessToken';
   OAUTH2_REDIRECT_URI : 'https://www.ushareandwin.com/oauth2/redirect',
   COMPANY_BASEURL : 'https://backend.ushareandwin.com',
   GROUP_BASEURL : 'https://backend.ushareandwin.com',
   USER_BASEURL : 'https://backend.ushareandwin.com',
   PARTNERSHIP_URL: 'https://backend.ushareandwin.com',
   MAIL_BASEURL : 'https://backend.ushareandwin.com',
   PAYMENT_BASEURL : 'https://backend.ushareandwin.com',
   GOOGLE_AUTH_URL : 'https://login.ushareandwin.com/oauth2/authorize/google?redirect_uri=https://www.ushareandwin.com/oauth2/redirect',
   FACEBOOK_AUTH_URL: 'https://login.ushareandwin.com/oauth2/authorize/facebook?redirect_uri=https://www.ushareandwin.com/oauth2/redirect',
   GITHUB_AUTH_URL : 'https://login.ushareandwin.com/oauth2/authorize/github?redirect_uri=https://www.ushareandwin.com/oauth2/redirect',

};
