import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { TokenStorageService } from '../auth/helpers/token-storage.service';
import { UserService } from '../services/user.service';
import { NotifService } from '../services/notif.service';
import { Notifications } from '../model/Notifications';
import { Router, ActivatedRoute, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { User } from '../model/user';
import { Offer } from '../model/Offer';
import { OfferDTO } from '../model/OfferDTO';
import { Comment } from '../model/Comment';
import { RestResponse } from '../model/RestResponse';
import { TranslateService } from '@ngx-translate/core';
import { AuthResponse } from '../auth/helpers/authresponse';
import { environment } from 'src/environments/environment';
import { CompanyService } from '../services/company.service';
import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { WebSocketService } from '../services/web-socket.service';
import Swal from 'sweetalert2';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { ShareService } from '../services/share.service';
import { OfferHandleService } from '../services/offer-handle.service';
import { ShareViaMailDto } from '../model/ShareViaMailDto';
import { Pagination } from '../model/Pagination';
import { Pageable } from '../model/Pageable';
import { PageableCount } from '../model/PageableCount';
import { Recherche } from '../model/recherche';
import { Filter } from '../model/filter';
import { Filtre } from '../model/filtre';
import { Client } from '@stomp/stompjs';
import { AuthService } from '../auth/helpers/auth.service';
import { FilterService } from '../_helpers/filter.service';
import { log } from 'console';

declare var FB: any;
declare var twttr: any;
export const urlEmoji = {
  Like: 'https://img.icons8.com/color/30/000000/facebook-like--v1.png',
  Amazing: 'https://img.icons8.com/emoji/30/000000/rolling-on-the-floor-laughing.png',
  Sad: 'https://img.icons8.com/color/30/000000/bored.png',
  inLove: 'https://img.icons8.com/fluent/30/000000/filled-like.png',
  Angry: 'https://img.icons8.com/officel/30/000000/angry.png',
};
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', './module-style.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  // name = 'ngx-sharebuttons';
  @Output() id = new EventEmitter<string>();
  info: any;
  flag = 0;
  count = 0;
  footer: boolean;
  urlPartage: any;
  notif_group = 0;
  // user: User;
  stClient: any;
  Like = false;

  notifications: any;
  messages: any;
  Amazing = false;
  inLove = false;
  angry = false;
  sad = false;
  notif_general = 0;
  postsNext: any[] = [];
  offerFilterS: any[] = [];
  criteriaFilter = { price: '0', brands: [], ctype: [], categories: [] };
  isLoggedIn = false;
  restResponse: RestResponse;
  authResponse: AuthResponse;
  selectedFiles: FileList;
  currentFile: File;
  progress = 0;
  message = '';
  isProfilePhote: boolean;
  firstName: string;
  lastName: string = '';
  userId: string;
  email: string;
  facebook: any = 'https://www.facebook.com/sharer.php?u=';
  twitter: any = 'https://twitter.com/share?url=';
  linkedin: any = 'https://www.linkedin.com/shareArticle?url=';
  whatapps: any = 'https://wa.me/?text=';
  address: string;
  closeResult: string;
  displayUserInfo: boolean;
  displayUserContact: boolean;
  displayBasicInfo: boolean;
  displayName: boolean;
  displayLastname: boolean;
  displayAddress: boolean;
  actionMessage: string;
  identifiant: string;
  user: User = new User();
  shareToken: string = this.companyService.sharingToken();
  // userAccount:UpdateAccount = new UpdateAccount();
  submitted = false;
  // isLoggedIn: boolean;
  loggInUser: User;
  url: string = environment.GATEWAY_API_URL;
  photoProfil: string;
  // restResponse : RestResponse;
  selectedFile: File = null;
  preview: string;
  loadingCommentState: any = {};
  pageno = 0;
  offerno = 0;
  notEmptyPost = true;
  notscrolly = true;
  // Attributs de gestion du status de l'action en cours
  actionLoading = false;
  actionSuccess = false;
  actionError = false;
  // actionMessage: string;
  recherche = new Recherche();
  find = false;
  accessToken: string;
  // Gestion du menu
  // isMenuCollapsed: boolean = true;
  public addresses: any[] = [];
  // Gestion du Sidebar
  isSideBarToggled = false;
  form: FormGroup;
  // Déclaration d'une liste de posts
  posts: any[] = [];
  usersLike: any[] = [];
  // Utilisateur connecté
  currentUser: any;
  // Commentaire courrant
  currentComment = '';
  // Commentaire courrant
  private currentCommentId = '';
  // Réponse au commentaire courrant
  private currentCommentReply = '';
  // L'utilisateur réponds à un commentaire
  private isReplying = false;
  // Déclaration d'un niveau de commentaire
  private h = 1;
  komment: Comment = new Comment();
  shouldBeconnected = false;
  mail = new ShareViaMailDto();
  idShouldBeconnected: string;
  private viewMoreItemId: string;
  private viewMoreClicked: boolean;
  actifEmoji = 'https://img.icons8.com/dotty/30/000000/facebook-like.png';
  private enableLike: boolean;
  name: any;
  offer: any;
  notificationsUnseen: any;
  twitterLink: string;
  loading: boolean;
  // Comment management
  makeCome: boolean;
  currentPostId: string;
  objetFiltre = new Filtre();
  commentViewIsShowed = false;
  gettingComments: boolean;
  commentInProgress: boolean;
  page = 0;
  load: boolean = false;
  private comments_tmp: any[] = [
    {
      id: 'wedbbsdhcdhcsh',
      message: 'Salut comment tu vas bout man?',
      offer: {},
      user: {},
      createdAt: new Date(),
      likesNb: 23,
      commentsNb: 32,
      comments: [],
    },
  ];

  // recherche:boolean;

  offersRawInfo: any;
  offers: Offer[] = [];
  pagesNumber: number;
  selectPageSize: number[];
  numberOfPages: number;
  paginationInfo: Pagination;
  offerNumber: any;
  /******* Pageable Information ********/
  private pageableItem: Pageable = {
    page: 0,
    size: PageableCount.SIZE,
  };
  pageItem = this.pageableItem.page;
  itemsPageItem = this.pageableItem.size;
  totalItems = this.pageableItem.size;
  companyFilterOfferUrl = environment.COMPANY_BASEURL + '/company/filterOffer';

  jeton: any = window.sessionStorage.getItem('accessToken');
  public editor;
  public editorContent = `<h3>I am Example content</h3>`;
  public editorOptions = {
    placeholder: 'insert content...',
  };
  itemsFilter: any[] = [];
  type: string;
  filter = false;
  offerFilter: any[] = [];
  constructor(private translate: TranslateService, private modalService: NgbModal, private token: TokenStorageService,
    private route: Router, private userService: UserService, private activatedRoute: ActivatedRoute, private companyService: CompanyService,
    private notificationService: NotifService, private toastr: ToastrService, private webService: WebSocketService,
    private spinner: NgxSpinnerService, private share: ShareService, private offerhandele: OfferHandleService, private auth: AuthService,
    private filterService: FilterService
  ) {
    translate.setDefaultLang('en');

    this.companyService.cmtObservable$.subscribe((cmt) => {
      this.setComment(cmt);
    }, error => {
      console.log('Could not load offer comment number')
    });
    this.route.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    })
  }

  /***look */
  navigationInterceptor(event: RouterEvent): void {
    //   console.log(event);
    if (event instanceof NavigationStart && event.url.includes('/user/payment/request')) {


      this.load = true
    }
    if (event instanceof NavigationEnd) {
      this.load = false
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.load = false
    }
    if (event instanceof NavigationError) {
      this.load = false
    }
  }

  receiveOffer($event) {
    this.recherche = $event;
    // console.log('fdsfdsf' + this.recherche);
  }

  // this method allow us to permute languages
  /*  useLanguage(language: string) {
     this.translate.use(language);
   } */
  fadeOUt() {
    $('#welcome').fadeOut().empty();
  }
  logout() {
    // this.token.signOut();
    this.isLoggedIn = false;
    this.userService.logout().subscribe(
      (data) => {
        // console.log(data);
      },
      (error) => { }
    );
    this.route.navigate(['/auth/login']);
  }
  receivename($event: boolean) {
    // this.name = $event;
  }

  gotoList() {
    this.route.navigate(['/home']);
  }
  /*Search() {
    console.log(this.posts);
    if (this.name !== '') {
      this.posts = this.posts.filter(res => {
        return res.title.toLowerCase().match(this.name.toLowerCase());
      });
      // console.log(this.groupes);

    } else if (this.name === '') {
      this.pageno = 0;
      this.ngOnInit();
    }
  }*/
  /***********************************************Filter*********************** */

  ngOnInit() {
    this.filterService.getFilter().subscribe((resp: any) => {
      this.itemsFilter = resp;
    })
    localStorage.setItem('component', 'home');
    setTimeout(() => {
      $('#welcome').fadeOut().empty();
    }, 10000);

    this.allOffers();
    // //alert(this.token.getUserInfos().id)
    // this.share.getTheid(this.token.getUserInfos().id);
    /** */
    (window as any).fbAsyncInit = () => {
      FB.init({
        appId: '245511887163805',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v11.0',
      });
    };

    ((d, s, id) => {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');

    /* *twitter        */
    (window as any).twttr = ((d, s, id) => {
      let js,
        fjs = d.getElementsByTagName(s)[0],
        t = (window as any).twttr || {};
      if (d.getElementById(id)) { return t; }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://platform.twitter.com/widgets.js';
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      t.ready = (f) => {
        t._e.push(f);
      };

      return t;
    })(document, 'script', 'twitter-wjs');

    this.unseenNotificationLists();
    this.groupToNotification(this.userId);
    this.notificationsUnread();
    this.id.emit(this.userId);
    $(document).ready(() => {
      $('.accordion')
        .find('.accordion__title')
        .on('click', function () {
          // Adds active class
          $(this).toggleClass('active');
          // Expand or collapse this panel
          $(this).next().slideToggle('fast');
          // Hide the other panels
          $('.accordion__content').not($(this).next()).slideUp('fast');
          // Removes active class from other titles
          $('.accordion__title').not($(this)).removeClass('active');
        });
      $('#partage').modal('show');

      let down = false;

      $('#bell').on('click', (e) => {
        // //alert("okok")
        const color = $(this).text();
        if (down) {
          $('#box').css({ height: '0px', opacity: '0', display: 'none' });
          down = false;
        } else {
          $('#box').css({ height: '480px', opacity: '1', display: 'block', 'overflow-y': 'scroll' });
          down = true;
        }
      });
    });
    console.log()
    this.init();
    this.groupToNotification(this.userId);
    this.notificationsUnread();
    const accessToken = this.activatedRoute.snapshot.queryParamMap.get('token');
    const refreshToken =
      this.activatedRoute.snapshot.queryParamMap.get('rtoken');
    const username = this.activatedRoute.snapshot.queryParamMap.get('username');
    const offerShareToken =
      this.activatedRoute.snapshot.queryParamMap.get('sharetoken');

    if (offerShareToken) {
      this.spinner.show();

      // console.log('Réseau sociaux  ' + offerShareToken);
      this.share.registerShare(offerShareToken).subscribe(
        (data) => {
          this.spinner.hide();
          localStorage.setItem('tokenSocialmedia', offerShareToken);
          this.route.navigate(['/home/singleOffer']);
        },
        (error) => {
          console.log(error);
          this.spinner.hide();
          console.log("erreur");

          console.log(error);

          this.toastr.error('Unable to see the Offer');
        }
      );
    }
    // console.log(accessToken + '+++++++++++++');

    // //alert(accessToken);
    // Récupérer les informations sur l'utilisateur connecté
    if (!accessToken) {
      // console.log('not social 1');
      // dans le cas ou on se connecte par voix normal
      const saveToken = window.sessionStorage.getItem('accessToken');

      this.getCurrentUser();
      // alert(1);
      // console.log('Im already normaly auth');

      // console.log("SaveToken", saveToken);
      // //alert(1);
      if (saveToken && saveToken !== 'null' && saveToken !== 'undefined') {
        // //alert(2);
        console.log('not social 2');
        const isSuccess = this.getCurrentUserOAuth();
        if (isSuccess) {
          // console.log(this.token);
          // alert(isSuccess);
          this.isLoggedIn = true;
          localStorage.setItem('social', 'yes');
          localStorage.setItem('main_role', 'USER')
        }
      } else {
        // console.log('not social 3');
        this.getCurrentUser();
        // console.log('Im not already auth');
        // cas ou on est pas connecté
        // //alert(3);
      }
      return;
    }
    // ici on utilise les reaseaux sociaux pour me connecter
    this.token.saveAccessToken(accessToken);
    this.token.saveRefreshToken(refreshToken);
    this.token.saveUsername(username);
    this.token.saveExpiryDuration(86400000);
    this.token.saveTokenType('Bearer');
    this.token.saveAuthorities(['USER']);
    const isSuccess = this.getCurrentUserOAuth();
    console.log('not social 4');
    console.log('IsSuccess: ', isSuccess);
    localStorage.setItem('social', 'yes');
    if (isSuccess) {
      console.log('not social 5');
      console.log(this.token);
      // //alert(isSuccess);
      this.isLoggedIn = true;
      localStorage.setItem('social', 'yes');
      this.isLoggedIn = true;

    }

  }

  saveOffer(id) {

    this.offerhandele.savedOffer(this.token.getUserInfos().id, id).subscribe(
      (data: any) => {
        this.toastr.success('Offer Save Successfully');
      },
      (error) => {
        this.toastr.error('Unable To Saved An Offer');
      }
    );
  }

  getUrl() {
    return this.twitterLink;
  }

  callBackTwitter() {
    // console.log(data);
    /* let toastSuccess=this.toastr.success("Offer Shared Successfully");
      let toastError = this.toastr.error("Unable To Share An Offer"); */
    twttr.ready((twttr) => {
      twttr.events.bind('like', (event) => {
        // your callback action here...
        Swal.fire(
          'Success',
          'Offer Shared  Successfully On Tweeter!',
          'success'
        );
        this.createMyShare(this.offer, 'SHARE');
        console.log(event);

      },
        (error) => {
          Swal.fire('failed', 'Offer has not been shared !', 'error');
        });
    });
    //this.createMyShare(this.offer, 'SHARE');
  }

  async facebookShare(link: any) {
    await FB.ui(
      {
        method: 'share',
        href: link.data,
      },
      // callback
      function (response) {
        if (response && !response.error_message) {
          // console.log(this.offer);
          //this.createMyShare(this.offer, 'SHARE');


          this.companyService
            .createSharer(this.token.getUserInfos().id, this.offer)
            .subscribe(
              (data) => {
                // console.log(data);
                // alert(data);
                // this.pageno = 0;
                // this.getOffers(10, this.pageno, 'id');
                this.ngOnInit();
              },
              (error) => {
                // console.log(error);
              }
            );

          Swal.fire('Success', 'Offer Shared Successfuly !!!', 'success');
        } else {
          // this.toastr.error("Unable To Share An Offer");
          Swal.fire('failed', 'Offer has not been shared !', 'error');
        }
      }
    );
  }
  callBackFacebook() {
    let share = JSON.parse(localStorage.getItem('share'));
    console.log(share);

    let obj = {
      media: 'facebook'
    }
    this.share.shareOffer(share.id, obj).subscribe((data: any) => {
      /*   localStorage.setItem('share',JSON.stringify(data.data))
  */
      //console.log(data);
      /* let toastSuccess=this.toastr.success("Offer Shared Successfully");
      let toastError = this.toastr.error("Unable To Share An Offer"); */
      this.createMyShare(this.offer, 'SHARE');

      FB.ui(
        {
          method: 'share',
          href: data.data,
        },
        // callback
        function (response) {
          if (response && !response.error_message) {
            console.log(this.offer);


            Swal.fire(
              'Success',
              'Offer Shared Successfuly !!!',
              'success'
            )


          } else {

            //this.toastr.error("Unable To Share An Offer");
            Swal.fire(
              'failed',
              'Offer has not been shared !',
              'error'
            );

          }
        }
      );

    })
  }

  reportOffer(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to report an offer ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, I want!',
      cancelButtonText: 'No, I don\'t',
    }).then((result) => {
      if (result.value) {
        this.offerhandele
          .reportOffer(this.token.getUserInfos().id, id)
          .subscribe((data) => {
            Swal.fire('Success', 'Offer reported', 'success');
          });

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled');
      }
    });
  }

  getlink(id) {
    // //alert(id)
    this.offer = id;

    this.share.getLink(id).subscribe((data: any) => {
      // console.log(data);
      localStorage.setItem('share', JSON.stringify(data.data));
      const share = data.data;
      const obj = {
        media: 'twitter',
      };
      this.share.shareOfferWithoutShare(share.id, obj).subscribe((data: any) => {
        /*   localStorage.setItem('share',JSON.stringify(data.data))
         */
        this.twitterLink = data.data;
        // console.log(data);
        /* let toastSuccess=this.toastr.success("Offer Shared Successfully");
      let toastError = this.toastr.error("Unable To Share An Offer"); */
        this.twitterLink =
          'https://twitter.com/intent/tweet?url=' + this.twitterLink;
      });
    });
  }

  public init(): void {
    // this.currentUser = data.user;
    if (localStorage.getItem('social') === 'yes') {
      this.isLoggedIn = true;
      const isSuccess = this.getCurrentUserOAuth();
      if (isSuccess) {
        // console.log(this.token);
      }
      this.getOffers(10, this.pageno, 'id');
    }
    if (this.token.getUserInfos()) {
      // //alert('-------------------olllrllrlrlrl')
      this.currentUser = this.token.getUserInfos();
      this.count = this.currentUser.groupNotification;
      if (this.currentUser.groupNotifications > 0) {
        document.getElementById('groupCount').innerHTML = ' ' + this.count;
      }

      // console.log(this.currentUser);
      this.share.getTheid(this.currentUser.id);
      // console.log(this.isLoggedIn);
      if (this.currentUser.imageUrl) {
        this.isProfilePhote = true;
        this.photoProfil =
          this.url + '/account/readMiniature/' + this.currentUser.imageUrl;
        // console.log(this.photoProfil);
        //  alert("ok tof")
      }

      this.isLoggedIn = true;
    }
    this.getOffers(10, this.pageno, 'id');
    // this.posts = data.posts;
    // this.shareToken = this.token.getUserInfos().id +"-"+new Date().getMilliseconds();
    /* //alert(this.token.getUserInfos().id)
    this.share.getTheid(this.token.getUserInfos().id); */
  }

  groupToNotification(identifiant: string) {
    if (this.isLoggedIn) {
      // console.log('receive notification for offers...');
      const serverUrl = environment.GATEWAY_API_UR;
      const ws = new SockJS(serverUrl + '/chat');
      // --- ws.onclose(() => { ws.connect(); });
      this.identifiant = this.token.getUserInfos().id;
      this.stClient = Stomp.over(ws);
      this.stClient.debug = () => { };
      const that = this;
      // //alert(this.userId);
      // tslint:disable-next-line:only-arrow-functions

      this.stClient.connect({}, function (frame) {
        that.stClient.debug = () => { };
        // //alert(name);
        // //alert(this.token.getUserInfos().id);

        // //alert(that.test);
        that.stClient.subscribe('/topic/groupNotif/' + that.identifiant,
          (response) => {
            //   //alert("read notif");
            // //alert(this.userId);
            const datum = response.data as Notifications;

            const data = JSON.parse(response.body);
            // console.log(data.groupNotification);
            //  console.log(data);
            document.getElementById('groupCount').innerHTML = '' + data.groupNotification;
            document.getElementById('generalNotifCount').innerHTML = '' + data.groupNotification;
          }
        );
      });
      // console.log(this.count);
      /*this.stClient = new Client();
      this.stClient.configure({
        brokerURL: serverUrl + '/chat',
        onConnect: () => {
          console.log('onConnect');
          this.stClient.subscribe('/topic/groupNotif/' + this.identifiant, message => {
            console.log('\nsubscribe: ', message);
            const datum = message.data as Notifications;

            const data = JSON.parse(message.body);
            console.log(data.groupNotification);
            //  console.log(data);
            document.getElementById('groupCount').innerHTML = '' + data.groupNotification;
            document.getElementById('generalNotifCount').innerHTML = '' + data.groupNotification;
          });
        },
        // Helps during debugging, remove in production
        debug: (str) => {
          console.log(new Date(), str);
        }
      });
      this.stClient.activate();*/
    }
  }

  getOffers(pagesize: number, pageno: number, sortby: string) {
    this.loading = true;
    this.companyService.getOffers(pagesize, pageno, sortby).subscribe(
      (data) => {
        if (data) {
          if (data.data) {
            const response = data.data as RestResponse;
            if (response.content) {
              this.posts = response.content as OfferDTO[];
              console.log(this.posts);
            }
            this.loading = false;

            this.flag = this.posts.length;
            // console.log(this.posts[0].avatar.uid);
          }
        }
      },
      (error) => { }
    );
  }

  public addPostLike(post: any): void {
    if (this.isLoggedIn) {
      post.likes_nb++;
      post.liked = true;
      return;
    }
    this.shouldBeconnected = true;
    this.idShouldBeconnected = post.id;
  }

  public removePostLike(post: any): void {
    post.likes_nb--;
    post.liked = false;
    document
      .getElementById(post.id)
      .setAttribute(
        'src',
        'https://img.icons8.com/dotty/30/000000/facebook-like.png'
      );
    this.enableLike = false;
  }

  public addCommentLike(item: any): void {
    item.likes_nb++;
    item.liked = true;
  }

  public removeCommentLike(item: any): void {
    item.likes_nb--;
    item.liked = false;
  }

  public replyComment(item: any): void {
    this.isReplying = true;
    this.currentCommentId = item.id;
  }

  public onReplyComment(item: any): void {
    // console.log(item);
    this.isReplying = false;
    this.currentCommentId = '';
    // console.log(this.currentCommentReply);
  }

  public checkIfIsCurrentReplyZone(item: any): boolean {
    return item.id === this.currentCommentId && this.isReplying;
  }

  public addComment(post): void {
    if (this.isLoggedIn) {
      if (this.currentComment) {
        this.commentInProgress = true;
        const comment = this.computeComment(post);
        // console.log(comment);
        if (!post.comments) {
          this.loadingCommentState[post.id] = true;
          this.companyService
            .getOfferComments(post.id)
            .toPromise()
            .then((data) => {
              // console.log(data);
              post.comments = data.data as Comment[];
              console.log('\n===> post: ' + JSON.stringify(post));
              console.log('\n===> data: ' + JSON.stringify(data));
              this.onAddComment(comment, post);
              this.commentInProgress = false;
              this.getCommentNombre(post);
            })
            .catch((error) => {
              console.log(error);
              this.commentInProgress = false;
            })
            .finally(() => {
              this.loadingCommentState[post.id] = false;
            });
        } else {
          this.onAddComment(comment, post);
        }
      }
      return;
    }
    this.idShouldBeconnected = post.id;
    this.shouldBeconnected = true;
  }

  public onAddComment(comment: any, post: Offer): void {
    this.companyService
      .createComment(comment, post.id, this.token.getUserInfos().id)
      .subscribe(
        (res) => {
          if (res && res.data) {
            if (post.comments) {
              post.comments.push(res.data as Comment);
              this.addComment(post);
            } else {
              post.comments = [];
              post.comments.push(res.data as Comment);
              this.commentInProgress = false;
            }
            this.getCommentNombre(res.data as any);
          }
        },
        (error) => {
          // console.log(error);
          this.commentInProgress = false;
          // this.notificationService.danger('Server error please check your network access!');
        }
      );
  }

  /* public addView(offerId: string, flag: string, url: string) {

        if(this.isLoggedIn) {
         this.companyService.createView(offerId, this.token.getUserInfos().id, flag, url).subscribe(
           data => {
             console.log(data);
           }, error => {
             console.log(error);
           }
         )
         return;
       }
       this.shouldBeconnected = true;
       this.idShouldBeconnected = offerId;
   }*/

  // Fonction qui incrimente le nombre de partages
  public addViewShare(offerId: string, flag: string) {
    this.companyService
      .createViewShare(offerId, this.token.getUserInfos().id, flag)
      .subscribe(
        (data) => {
          // console.log(data);
          this.pageno = 0;
          this.getOffers(10, this.pageno, 'id');
        },
        (error) => {
          // console.log(error);
        }
      );
    return;
  }

  // Infinity Scroll
  public allOffers() {
    this.companyService.getAllOffers().subscribe(
      (data: any) => {
        const offers = data.data as Offer[];
        const offerNumber = offers.length;
        let totalPageNum;
        if (offerNumber % 10 == 0) {
          totalPageNum = offerNumber / 10;
        } else if (offerNumber % 10 > 0) {
          totalPageNum = 1 + Math.floor(offerNumber / 10);
        }

        window.addEventListener('scroll', () => {
          const scrollable =
            document.documentElement.scrollHeight - window.innerHeight;

          const scrolled = window.scrollY;

          if (
            window.innerHeight + window.pageYOffset >=
            document.body.offsetHeight
          ) {
            this.pageno++;
            if (this.pageno < totalPageNum) {
              this.getOffersNext(10, this.pageno, 'id');
            } else if (this.pageno == totalPageNum) {
              this.getOffersNext(10, this.pageno, 'id');
              this.footer = true;
            }
          }
        });
      },
      (error) => { }
    );
  }

  public addView(offerId: string, flag: string) {
    /*   console.log(offerId);
      console.log(flag); */

    if (flag === 'LIKE') {
      document.getElementById(offerId).setAttribute('src', urlEmoji.Like);
    } else if (flag === 'AMAZING') {
      document.getElementById(offerId).setAttribute('src', urlEmoji.Amazing);
    } else if (flag === 'SAD') {
      document.getElementById(offerId).setAttribute('src', urlEmoji.Sad);
    } else if (flag === 'LOVE') {
      document.getElementById(offerId).setAttribute('src', urlEmoji.inLove);
    } else if (flag === 'ANGRY') {
      document.getElementById(offerId).setAttribute('src', urlEmoji.Angry);
    }

    if (this.isLoggedIn) {

      // alert(this.token.getUserInfos().id);
      this.companyService
        .createView(offerId, this.token.getUserInfos().id, flag)
        .subscribe(
          (data) => {
            // console.log(data);
            this.pageno = 0;
            this.getOffers(10, this.pageno, 'id');
          },
          (error) => {
            // console.log(error);
          }
        );
      return;
    }
    this.shouldBeconnected = true;
    this.idShouldBeconnected = offerId;
  }

 
  /*  public createMyShare(offerId: string, flag: string) {

    if (this.isLoggedIn) {
      ////alert(this.token.getUserInfos().id);
      this.companyService.createShare(offerId, this.token.getUserInfos().id, flag).subscribe(
        data => {
          console.log(data);
          this.pageno = 0;
          this.getOffers(10, this.pageno, 'id');
        }, error => {
          console.log(error);
        }
      );
      return;
    }
    this.shouldBeconnected = true;
    this.idShouldBeconnected = offerId;
  } */

  public createMyShare(offerId: string, flag: string) {
    if (this.isLoggedIn) {
      // //alert(this.token.getUserInfos().id);
      this.companyService
        .createSharer(this.token.getUserInfos().id, offerId)
        .subscribe(
          (data) => {
            // console.log(data);
            // alert(data);
            // this.pageno = 0;
            // this.getOffers(10, this.pageno, 'id');
            this.ngOnInit();
          },
          (error) => {
            // console.log(error);
          }
        );
      return;
    }

    this.shouldBeconnected = true;
    this.idShouldBeconnected = offerId;
  }

  public clearCurrentComment(): void {
    this.currentComment = '';
  }

  public computeComment(post: Offer): any {
    // console.log(this.currentComment);
    // return new Comment();
    const comment: any = {
      // Il s'agit d'un commentaire racine qui n'a pas de parent
      parentId: null,
      // id: post.comments_list.length + '',
      message: this.currentComment,
      user: this.currentUser,
      // createdAt: new Date(),
      likesNb: 0,
      commentsNb: 0,
      // liked: false,
      comments: [],
    };
    /*if(post.comments) {
      post.comments.push(comment);
    } else {
      post.comments = [];
      post.comments.push(comment);
    }*/
    // post.commentsNb++;
    this.currentComment = '';
    return comment;
  }

  public computeReplyZoneId(item: any): string {
    return 'reply-' + item.id;
  }

  public displayLastPostTime(postDate: Date): string {
    const difference_In_Time = new Date().getTime() - postDate.getTime();
    const difference_In_Seconds = Math.floor(difference_In_Time / 1000) + 1;
    if (difference_In_Seconds < 60) {
      return difference_In_Seconds + ' second(s) ago';
    }
    const difference_In_Minutes = Math.floor(difference_In_Time / (1000 * 60));
    if (difference_In_Minutes < 60) {
      return difference_In_Minutes + ' min ago';
    }
    const difference_In_Hours = Math.floor(
      difference_In_Time / (1000 * 60 * 60)
    );
    if (difference_In_Hours < 24) {
      return difference_In_Hours + ' hour(s) ago';
    }
    const difference_In_Days = Math.floor(
      difference_In_Time / (1000 * 60 * 60 * 24)
    );
    if (difference_In_Days < 30) {
      return difference_In_Days + ' day(s) ago';
    }
    const difference_In_Months = Math.floor(
      difference_In_Time / (1000 * 60 * 60 * 24 * 30)
    );
    if (difference_In_Months < 365) {
      return difference_In_Months + ' Month(s) ago';
    }
    const difference_In_Years = Math.floor(
      difference_In_Time / (1000 * 60 * 60 * 24 * 30 * 365)
    );
    if (difference_In_Years <= 365) {
      return difference_In_Years + ' Years(s) ago';
    }
  }

  public focusTo(id: string): void {
    document.getElementById(id + '').focus();
  }

  public computeId(prefix: string, suffix: string): string {
    return prefix + suffix + '';
  }

  public displayPostAvatar(uid: string): string {
    return this.url + '/account/read/' + uid;
  }

  public displayPostAvatarMedium(uid: string): string {
    return this.url + '/account/readMedium/' + uid;
  }

  public displayPostAvatarMiniature(uid: string): string {
    return this.url + '/account/readMiniature/' + uid;
  }

  getCurrentUser() {
    this.userService.getMyAccount().subscribe(
      (data) => {
        const status = data.status;
        if (status === 'SUCCESS') {
          // L'opération s'est effectuée avec succès

          // récupérer la donnée
          const currentUser = data.data as User;
          // permmetre à l'objet recupéré être utilisé dans la vue (exemple : loggInUser.firstName)
          this.loggInUser = currentUser;
          this.isLoggedIn = true;
          // enregistrement des informations du user dans le local Storage
          localStorage.setItem('firstName', this.loggInUser.firstName);
          localStorage.setItem('lastName', this.loggInUser.lastName);
          localStorage.setItem('email', this.loggInUser.email);
          localStorage.setItem('address', this.loggInUser.address);
          // //alert("avantTof");
          if (
            this.loggInUser.imageUrl !== '' &&
            localStorage.getItem('social') !== 'yes'
          ) {
            this.isProfilePhote = true;
            this.photoProfil =
              this.url + '/account/readMiniature/' + this.loggInUser.imageUrl;
            // console.log(this.photoProfil);
          } else {
            this.isProfilePhote = false;
          }
        } else {
          // Un problème est survenue lors de l'opération

          this.actionMessage = data.message;
          // in case there is a problem, display the below message in a toast
          // this.toastService.show('Impossible to load profil user!', { classname: 'bg-danger text-light', delay: 5000 })
        }
      },
      (error) => {
        // Une erreur est intervenue lors d l'opération

        this.actionMessage =
          'An error occured while searching for projects, please try later';
        // console.log(this.actionMessage);
      }
    );
  }
  //
  public displayAvatar(post: Offer): string {
    if (post.publisher) {
      if (post.publisher) {
        if (post.publisher.photo) {
          // return this.url + '/account/read/' + post.publisher.photo.uid;
        }
      }
    }
    return '';
  }

  getCurrentUserOAuth(): boolean {
    this.userService.getMyAccount().subscribe(
      (data) => {
        const status = data.status;
        if (status === 'SUCCESS') {
          // L'opération s'est effectuée avec succès

          // récupérer la donnée
          const currentUser = data.data as User;
          // permmetre à l'objet recupéré être utilisé dans la vue (exemple : loggInUser.firstName)
          this.loggInUser = currentUser;
          this.isLoggedIn = true;
          // enregistrement des informations du user dans le local Storage
          this.token.saveUserInformations(
            this.loggInUser.firstName,
            this.loggInUser.lastName,
            this.loggInUser.email,
            this.loggInUser.address
          );
          this.token.saveUserInfos(currentUser);

          if (
            this.loggInUser.imageUrl !== '' &&
            localStorage.getItem('social') === 'yes'
          ) {

            localStorage.setItem('main_role', 'USER')
            this.isProfilePhote = true;
            this.photoProfil = this.loggInUser.imageUrl;
          } else if (
            this.loggInUser.imageUrl !== '' &&
            localStorage.getItem('social') !== 'yes'
          ) {
            ;
            localStorage.setItem('main_role', 'USER')
            this.isProfilePhote = true;
            this.photoProfil =
              this.url + '/account/readMiniature/' + this.loggInUser.imageUrl;
            // console.log(this.photoProfil);
          } else {
            this.isProfilePhote = false;
          }

          return true;
        }
        // Un problème est survenue lors de l'opération

        this.actionMessage = data.message;
        return false;
        // in case there is a problem, display the below message in a toast
        // this.toastService.show('Impossible to load profil user!', { classname: 'bg-danger text-light', delay: 5000 })
      },
      (error) => {
        // Une erreur est intervenue lors d l'opération
        this.actionMessage =
          'An error occured while searching for projects, please try later';
        // console.log(this.actionMessage);
        return false;
      }
    );
    return false;
  }

  public getComments(post: Offer): void {
    if (this.isLoggedIn) {
      if (post.id) {
        this.loadingCommentState[post.id] = true;
        this.companyService
          .getOfferComments(post.id)
          .toPromise()
          .then((data) => {
            // console.log(data);
            post.comments = data.data as Comment[];
            console.log(post.comments[0].message);
            this.loadingCommentState[post.id] = false;
          })
          .catch((error) => {
            // console.log('Error: ', error);
            this.loadingCommentState[post.id] = false;
            if (error.status === 404) {
              Swal.fire('Error', 'This post has no comments!', 'error');
              // this.notificationService.danger('This post has no comments!');
              post.comments = [];
              return;
            }
            Swal.fire(
              'Error',
              'Server error please check your network access!',
              'error'
            );
            //  this.notificationService.danger('Server error please check your network access!');
          });
      }
      return;
    }
    this.idShouldBeconnected = post.id;
    this.shouldBeconnected = true;
  }

  public addPostComment(post: Offer): void {
    if (!this.isLoggedIn) {
      this.idShouldBeconnected = post.id;
      this.shouldBeconnected = true;
    }
    this.focusTo('make_comment_' + post.id);
  }

  public sharePost(post: Offer): void {
    if (!this.isLoggedIn) {
      this.idShouldBeconnected = post.id;
      this.shouldBeconnected = true;
    }
  }

  public checkViewMoreClicked(postId: string): boolean {
    return this.viewMoreClicked && postId === this.viewMoreItemId;
  }

  public checkViewMoreNotClicked(postId: string): boolean {
    return !this.viewMoreClicked || postId !== this.viewMoreItemId;
  }

  public seeMore(id: string): void {
    this.viewMoreItemId = id;
    this.viewMoreClicked = true;
  }

  public displayPostDescription(description: string): string {
    if (description.length < 20) {
      return description;
    } else {
      return description.substring(0, 20) + '';
    }
  }




  public displayComments(post: Offer): Comment[] {
    // --- this.getCommentNombre(post);
    // console.log('\n===>2 comments: ' + JSON.stringify(this.posts.find(p => (p.id === post.id)).comments));
    return this.posts.find(p => (p.id === post.id)).comments; // post.comments;
  }

  public setOffer(offer: Offer, level: number): void {
    this.offer = offer;
    if (level == 1) {
      this.posts.map((p, i) => {
        if (p.id == offer.id) {
          this.posts[i].commentsNb = offer.commentsNb;
        }
      });
    } else if (level == 2) {
      this.recherche.elementRechercher.map((p, i) => {
        if (p.id == offer.id) {
          this.recherche.elementRechercher[i].commentsNb = offer.commentsNb;
        }
      });
    } else {
      this.offerFilter.map((p, i) => {
        if (p.id == offer.id) {
          this.offerFilter[i].commentsNb = offer.commentsNb;
        }
      });
    }
  }

  public setComment(comment: Comment): void {
    /*let ids = comment.parentId.split("@");
    if (ids.length == 2) {
      this.posts.find(p => (p.id === comment.offer.id)).comments
      .find(c => (c.id === ids[0])).comments
      .find(c => (c.id === comment.parentId)).comments.push(comment);
    } else if (ids.length == 3) {
      this.posts.find(p => (p.id === comment.offer.id)).comments
      .find(c => (c.id === ids[0])).comments
      .find(c => (c.id === ids[0].concat("@", ids[1]))).comments
      .find(c => (c.id === comment.parentId)).comments.push(comment);
    }*/
    let n = this.posts.find(p => (p.id === comment.offer.id)).comments != null ? this.posts.find(p => (p.id === comment.offer.id)).comments.length : 0;
    if (n == 0) {
      this.updateOfferComments(this.posts.find(p => (p.id === comment.offer.id)), comment, null);
    } else {
      for (let index = 0; index < n; index++) {
        this.updateOfferComments(this.posts.find(p => (p.id === comment.offer.id)), comment, this.posts.find(p => (p.id === comment.offer.id)).comments[index]);
      }
    }
  }

  public updateOfferComments(post: Offer, comment: Comment, parent: Comment) {
    if (comment.parentId == null) {
      this.posts.map((p, i) => {
        if (p.id == post.id) {
          p.commentsNb = comment.offer.commentsNb;
          p.comments.push(comment);
        }
      });
    } else if (comment.parentId == parent.id) {
      this.posts.map((p, i) => {
        if (p.id == post.id) {
          p.commentsNb = comment.offer.commentsNb;
        }
      });
      if (parent.comments.indexOf(comment) == -1) {
        parent.comments.push(comment);
        parent.commentsNb = parent.comments.length;
      }
    } else {
      let n = parent.comments != null ? parent.comments.length : 0;
      if (n > 0) {
        for (let index = 0; index < n; index++) {
          this.updateOfferComments(post, comment, parent.comments[index]);
        }
      }
    }
  }

  public cancel() {
    this.shouldBeconnected = false;
    this.idShouldBeconnected = '';
  }

  goToAuth(url) {
    this.route.navigate(['/auth/' + url]);
  }

  goToUser(url) {
    this.route.navigate(['/user/' + url]);
  }

  goToHome() {
    this.route.navigate(['/home']);
  }

  setEmojiSimp(link, id, flag) {
    // this.actifEmoji = link;
    if (this.isLoggedIn) {
      document.getElementById(id).setAttribute('src', link);
      this.enableLike = true;
      this.addView(id, flag);
    }
  }
  onScroll() {
    const scrollable = document.documentElement.scrollHeight - window.innerHeight;
    const scrolled = window.scrollY;
    console.log(scrollable);
    if (scrollable === scrolled) {
    }
    // //alert(scrollable)
    /*  if (this.notscrolly && this.notEmptyPost) {
      this.pageno++;
     // //alert(this.pageno)
     // this.spinner.show();
      this.getOffersNext(10, this.pageno, 'id');
    } */

    // //alert('scrolled');
  }
  /*  openVerticallyCentered(content) {
     this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
       this.closeResult = `Closed with: ${result}`;
     }, (reason) => {
       // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
     });
   } */

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    Swal.fire('Success', 'The Url is Copied!!!', 'success');
    // this.toastr.success('The Url is Copied!!!', );
  }
  checkConnected(id) {
    if (!this.isLoggedIn) {
      this.idShouldBeconnected = id;
      this.shouldBeconnected = true;
    }
  }

  filtre(filter: Filter) {
    // console.log(filter);

    this.companyService
      .getfilter(filter, 10, this.page, 'id')
      .subscribe((data: any) => {
        this.offerFilter = data.data.content;
        document.getElementById('fil').style.display = 'none';
        document.getElementById('filtre').style.display = 'block';
        // console.log(this.offerFilter);
      });
  }
  see(valeur, brand, ctype, selection) {
    const value = parseInt(valeur);
    const brands: any = [];
    const selections: any = [];

    // console.log(ctype);
    // alert(event.target.valu
    if (brand.checked) {
      brands.push(brand.value);
    } else {
      const index = brands.indexOf(brand.value);
      if (index > -1) {
        brands.splice(index, 1);
      }
    }
    if (selection.checked) {
      selections.push(selection.value);
    } else {
      const index = brands.indexOf(selection.value);
      if (index > -1) {
        brands.splice(index, 1);
      }
    }
    if (value <= 0 && brands.length == 0 && selections.length == 0 && ctype == 'none') {
      console.log('rechargement des offers');
      document.getElementById('filtre').style.display = 'none';
      document.getElementById('fil').style.display = 'block';
      this.getOffers(10, this.pageno, 'id');
    } else {
      this.filter = true;
      const valuectype = ctype === 'true';
      // console.log(valuectype);

      const obj = {
        valeur: parseInt(valeur),
        brand: brands,
        ctype: valuectype,
        type: selections,
      };
      this.companyService.getfilter(obj, 10, this.page, 'id')
        .subscribe((data: any) => {
          // console.log(data.data.content);
          this.offerFilter = data.data.content;
          document.getElementById('fil').style.display = 'none';
          document.getElementById('filtre').style.display = 'block';
          const offerNumber = this.offerFilter.length;
          let totalPageNum;
          if (offerNumber % 10 === 0) {
            totalPageNum = offerNumber / 10;
          } else if (offerNumber % 10 > 0) {
            totalPageNum = 1 + Math.floor(offerNumber / 10);
          }

          window.addEventListener('scroll', () => {

            const scrollable = document.documentElement.scrollHeight - window.innerHeight;
            const scrolled = window.scrollY;

            if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
              this.offerno++;
              if (this.offerno < totalPageNum) {
                this.offerFilterNext(obj, 10, this.offerno, 'id');
              } else if (this.offerno === totalPageNum) {
                this.offerFilterNext(obj, 10, this.offerno, 'id');
                this.footer = true;
              }
            }
          });
        });
    }
  }
  offerFilterNext(obj, pagesize: number, pageno: number, sortby: string) {
    this.loading = true;
    this.companyService.getfilter(obj, pagesize, pageno, sortby).subscribe((data: any) => {
      this.loading = false;
      // console.log(data.data);
      this.offerFilterS = data.data.content;

      this.offerFilter = this.offerFilter.concat(this.offerFilterS);
      this.offerFilterS = [];
      this.notscrolly = true;

    });
  }
  getOffersNext(pagesize: number, pageno: number, sortby: string) {
    this.loading = true;
    this.companyService.getOffers(pagesize, pageno, sortby).subscribe(
      (data: any) => {
        this.loading = false;
        // console.log(data.data);
        const response = data.data as RestResponse;
        if (!response.data) {
          this.loading = false;
        }

        this.postsNext = response.content as OfferDTO[];

        // add newly fetched posts to the existing post
        this.posts = this.posts.concat(this.postsNext);
        this.postsNext = [];
        this.notscrolly = true;
        /* if (data) {
          if (data.data) {
            const response = data.data as RestResponse;
            if (response.content) {
            this.posts = this.posts.concat(this.postsNext);
            this.notscrolly = true;
          }
        }
        }*/
      },
      (error) => { }
    );
  }
  // //alert("Copied the text: " + copyText..);
  loadOffersReaction(offerId, flag) {
    let i = 0;

    this.companyService.getLike(offerId, flag).subscribe((data: any) => {
      // console.log('Reaction data....>>>>>>');
      // console.log(data);

      if (undefined !== data.data.content && data.data.content.length) {
        if (!data.data.content) {
          // console.log('no data');
        }

        while (i < data.data.content.length) {
          if (data.data.content[i].enumOfferViewType === 'LIKE') {
            this.Like = true;
          } else if (data.data.content[i].enumOfferViewType === 'AMAZING') {
            this.Amazing = true;
          } else if (data.data.content[i].enumOfferViewType === 'SAD') {
            this.sad = true;
          } else if (data.data.content[i].enumOfferViewType === 'ANGRY') {
            this.angry = true;
          } else if (data.data.content[i].enumOfferViewType === 'INLOVE') {
            this.inLove = true;
          }
          i++;
        }
      } else {
        // console.log ("empty");
      }

      this.usersLike = data.data.content;
      // console.log(this.usersLike);
    });
  }
  save(offerId, shareToken) {
    // console.log(shareToken);
    if (this.isLoggedIn) {
      this.companyService
        .saveOffers(offerId, this.loggInUser.id, shareToken)
        .subscribe(
          (data) => {
            console.log(data);
          },
          (error) => {
            // console.log(error);
          }
        );
    } else {
      this.route.navigate(['/auth/login']);
    }
  }
  removeAddress(i: number) {
    this.addresses.splice(i, 1);
  }
  openVerticallyCentered(content, id) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  addAddress() {
    const obj = {
      email: '',
    };
    this.addresses.push(obj);
    // console.log(this.addresses);
  }
  shareLinkConnected() {
    if (!this.isLoggedIn) {
      Swal.fire('Error', 'You can \'t share and Win when you are not log in', 'error');
      // this.notificationService.danger('You are Not Connected');
    }
  }
  // tslint:disable-next-line:max-line-length

  shareAlgorithm(media, offerId, shareToken) {
    this.companyService.shareOffers(media, offerId, this.loggInUser.id, shareToken)
      .subscribe(
        (data) => {
          // console.log(data);
        },
        (error) => { }
      );
  }

  /**
   * Modal for reactions
   */
  open(content) {
    this.modalService.open(content, { centered: true }).result.then(
      (result) => {
        // this.closeResult = `Closed with: ${result}`;
        this.closeResult = ``;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  /*private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }*/

  public displayUserAvatar(uid: string): string {
    return environment.GATEWAY_API_URL + '/account/read/' + uid;
  }

  // Comment Function
  submitMail() {
    const share = JSON.parse(localStorage.getItem('share'));
    // console.log(share);

    // let url = environment.serverEndpoint+'/share?shareId='+share.id;
    this.mail.message = this.messages;
    this.mail.emailList = this.addresses;
    // console.log(this.mail);

    this.share.shareOfferByMail(share.id, this.mail).subscribe(
      (data: any) => {
        // console.log(data);
        this.toastr.success('Email Sent!!');

        this.createMyShare(this.offer, 'SHARE');
      },
      (error) => {
        this.toastr.error('Cannot send a mail');
      }
    );
  }
  showCommentSection(post: any) {
    post.comments = [];
    if (this.commentViewIsShowed) {
      this.currentPostId = null;
      this.commentViewIsShowed = false;
      this.makeCome = false;
    } else {
      this.commentViewIsShowed = true;
      this.currentPostId = post.id;
      this.gettingComments = true;
      this.makeCome = true;
      // this.getComments(post);
      this.getOfferComment(post);
    }
  }
  public getCommentNombre(post) {

    // --- console.log('\n2 - CommentNumber of ' + post.id + ': ' + JSON.stringify(post));
    // --- console.log('\n3 - CommentNumber of ' + post.id + ': ' + JSON.stringify(post.offer));
    if (post.id !== undefined) {
      console.log('\n2 - CommentNumber of ' + post.id + ': ' + JSON.stringify(post));
      if (post.offer !== undefined) {
        // this.posts.find(p => (p.id === post.offerId)).commentsNb = post.offer.commentsNb;
        /*let currentPost = this.posts.find(p => (p.id === post.offerId));
        currentPost.commentsNb += 1;*/
        this.posts.find(p => (p.id === post.offerId)).commentsNb = post.offer.commentsNb;
      }
    } else {
      console.log('\n3 - CommentNumber of ' + post.id + ': ' + JSON.stringify(post.offer));
      this.posts.find(p => (p.id === post.offerId)).commentsNb = post.offer.commentsNb;
      // this.posts.find(p => (p.id === post.offerId)).commentsNb = post.offer.commentsNb;
    }
  }


  private getOfferComment(post: any) {
    this.companyService.getCommentsByOffer(post.id).toPromise()
      .then((data: RestResponse) => {
        const comments = data.data as Comment[];
        post.comments = comments.sort(
          (a, b) => +new Date(b.createdAt) - +new Date(a.createdAt)
        ).reverse();
        if (post.comments !== undefined && post.comments.length > 0) {
          post.comments.forEach((cmt) => {
            this.setCommentsNumbers(cmt);
          });
        }
        this.gettingComments = false;
        this.addPostComment(post);
        this.commentInProgress = false;
        this.getCommentNombre(post);
      })
      .catch((error) => {
        this.gettingComments = false;
        // console.log('Error: ', error);
        post.comments = [];
        this.addPostComment(post);
      });
  }

  private setCommentsNumbers(comment: Comment) {
    if (comment.comments !== undefined && comment.comments.length > 0) {
      comment.commentsNb = comment.comments.length;
      comment.comments.forEach((cmt) => {
        this.setCommentsNumbers(cmt);
      });
    } else {
      comment.commentsNb = 0;
    }
  }

  public addCommentV2(post): void {
    this.commentInProgress = true;
    const comment = this.computeComment(post);
    this.companyService
      .createComment(comment, post.id, this.token.getUserInfos().id)
      .subscribe(
        (data) => {
          // console.log('After comment: ', data);
          if (data.code === 201) {
            this.getOfferComment(post);
          }
        },
        (error) => {
          // console.log(error);
          this.commentInProgress = false;
          // this.notificationService.danger('Server error please check your network access!');
        }
      );
  }

  postHasThisReaction(post: any, like: string): boolean {
    let result = false;
    this.companyService.getLike(post.id, like).subscribe(
      (data: any) => {
        // console.log('Reaction data....>>>>>>');
        if (!data.data.content) {
          // console.log('no data');
          result = false;
        } else {
          const reactions = data.data.content as any[];
          result =
            reactions.filter(
              (reaction) => reaction.enumOfferViewType === like
            )[0] !== -1;
        }
      },
      (error) => {
        result = false;
      }
    );
    return result;
  }

  notificationsUnreadToRead(id) {
    this.webService
      .unseenToSeen(this.token.getUserInfos().id, id)
      .subscribe((data) => {
        console.log('WEBSOCKET _____________');

        console.log(data.data);
        this.notificationsUnread();
        this.route.navigate(['/user/group']);
      });
  }

  // On retourne toute la liste des notifications du user de la plus jeune à la plus vieille notif
  notificationsUnread() {
    if (this.isLoggedIn) {
      this.webService
        .notificationList('GROUP', this.token.getUserInfos().id)
        .subscribe((data) => {
          // alert("ok")
          // console.log(data.data);
          this.notifications = data.data;
        });
    }
  }
  unseenNotificationLists() {
    //// liste des notifications de group "non vues" par le user
    if (this.isLoggedIn) {
      this.webService
        .unseenNotificationLists(
          'UNSEEN',
          'GROUP',
          this.token.getUserInfos().id
        )
        .subscribe((data) => {
          // alert("ok")
          // console.log(data.data);
          this.notificationsUnseen = data.data;
          this.notif_group = this.notificationsUnseen.length;
          this.notif_general = this.token.getUserInfos().generalNotification;
        });
    }
  }

  generalUnseenToSeen(type: string) {
    if (this.isLoggedIn) {
      this.webService
        .generalUnseenToSeen(type, this.token.getUserInfos().id)
        .subscribe((data) => {
          // console.log(data.data);
          this.notifications = data.data;
          this.notif_group = this.notifications.groupNotification;
          this.notif_general = this.notifications.generalNotification;
        });
    }
  }

  getFilterCriteriaProduct(event: any) {
    if (event.target.name === 'price') {
      this.criteriaFilter.price = event.target.value;
      $('#rangevale').html(event.target.value);
      $('#rangevalemobile').html(event.target.value);
    } else if (event.target.name === 'mobile') {
      $('#rangevalemobile').html(event.target.value);
      this.criteriaFilter.price = event.target.value;
    }

    else if (event.target.name === 'brands') {
      if (this.criteriaFilter.brands.indexOf(event.target.value) === -1) {
        this.criteriaFilter.brands.push(event.target.value);
      } else {
        this.criteriaFilter.brands.splice(this.criteriaFilter.brands.indexOf(event.target.value), 1);
      }
    } else if (event.target.name === 'ctype') {
      if (this.criteriaFilter.ctype.indexOf(event.target.value) === -1) {
        this.criteriaFilter.ctype.push(event.target.value);
      } else {
        this.criteriaFilter.ctype.splice(this.criteriaFilter.ctype.indexOf(event.target.value), 1);
      }
    } else if (event.target.name === 'cathegories') {
      if (this.criteriaFilter.categories.indexOf(event.target.value) === -1) {
        this.criteriaFilter.categories.push(event.target.value);
      } else {
        this.criteriaFilter.categories.splice(this.criteriaFilter.categories.indexOf(event.target.value), 1);
      }
    }

    if (this.isFilterEmpty()) {
      if (document.getElementById('filtre').style.display === 'block') {
        document.getElementById('fil').style.display = 'block';
        document.getElementById('filtre').style.display = 'none';
        this.pageno = 0;
      }
      this.allOffers();
      /*  this.companyService.getOffers(10, this.pageno, 'id').subscribe(
         (data) => {
           if (data) {
             if (data.data) {
               const response = data.data as RestResponse;
               if (response.content) {
                 this.posts = response.content as Offer[];
               }

               this.flag = this.posts.length;
               // console.log(this.posts[0].avatar.uid);
             }
           }

            window.addEventListener('scroll', () => {
           const scrollable =
             document.documentElement.scrollHeight - window.innerHeight;

           const scrolled = window.scrollY;

           if (
             window.innerHeight + window.pageYOffset >=
             document.body.offsetHeight
           ) {
             this.pageno++;
             if (this.pageno < totalPageNum) {
               this.getOffersNext(10, this.pageno, 'id');
             } else if (this.pageno == totalPageNum) {
               this.getOffersNext(10, this.pageno, 'id');
               this.footer = true;
             }
           }
         });

         },
         (error) => {}
       ); */
    } else {
      if (document.getElementById('filtre').style.display === 'none') {
        document.getElementById('fil').style.display = 'none';
        document.getElementById('filtre').style.display = 'block';
        this.page = 0;
        this.offerno = 0;
      }
      this.companyService.getfilter(this.criteriaFilter, 10, this.page, 'id').subscribe((data: any) => {
        this.offerFilter = data.data.content;
        const totalPageNum = data.data.totalPage;

        window.addEventListener('scroll', () => {
          if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
            this.offerno++;
            if (this.offerno < totalPageNum) {
              this.offerFilterNext(this.criteriaFilter, 10, this.offerno, 'id');
            } else if (this.offerno === totalPageNum) {
              this.offerFilterNext(this.criteriaFilter, 10, this.offerno, 'id');
              this.footer = true;
            }
          }
        });
      });
    }
  }

  isFilterEmpty() {
    // tslint:disable-next-line:radix
    if (parseInt(this.criteriaFilter.price) === 0 && this.criteriaFilter.brands.length === 0
      && this.criteriaFilter.ctype.length === 0 && this.criteriaFilter.categories.length === 0) {
      return true;
    }
    return false;
  }

  startPayment(price: any, title: any, post: any) {

    //this.load = true;
    //if (this.isLoggedIn) {
     
      localStorage.setItem("producttobuy", JSON.stringify(post))
      this.route.navigate(['/user/payment/request'], { queryParams: { 'price': price, 'title': title } }).then((event) => {
        // alert('okokok')
        this.load = false;
      });
    //}

    // const url = this.route.serializeUrl(this.route.createUrlTree(['/user/payment/request'], { queryParams: {price}}));
    // const winOp = window.open(url, '_blank');  // _blank, _self
    /*window.setTimeout(() => {
      winOp.close();
    }, 1000);*/
  }
}
