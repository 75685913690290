import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../environments/environment';
import {Observable, Observer} from 'rxjs';


@Injectable()
export class PaymentService {

  payments$: Observable<any>;
  private paymentsObserver: Observer<any>;

  payment$: Observable<any>;
  private paymentObserver: Observer<any>;

  private dataStorage: {
    payments: any[]
    payment: any
  };

  private handleError(error: any) {
    const errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return Promise.reject(errMsg);
  }

  private extractData(res: HttpResponse<object>) {
    let body;
    if (res) {
      // @ts-ignore
      body = res.body.data;
    }
    return body;
  }

  constructor(private httpClient: HttpClient, public translate: TranslateService) {

    this.payments$ = new Observable<any[]>(observer => {
      this.paymentsObserver = observer;
    });
    this.payment$ = new Observable<any>(observer => {
      this.paymentObserver = observer;
    });

    this.dataStorage = { payments: [] , payment: {} };
  }

  // ---------------------------------------------------------------------------------------------------------------------------------------
  paypalcreatepayement(createPayment: any) {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.post(environment.PAYMENT_BASEURL  + '/paypal/request/payment', createPayment, { observe: 'response', responseType: 'json' })
      .toPromise().then((res: HttpResponse<any>) => res)
      .catch(this.handleError);
  }

  
  // ---------------------------------------------------------------------------------------------------------------------------------------
  paypalexecutepayement(executePayment: any,productID:string) {

    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(environment.PAYMENT_BASEURL  + '/paypal/confirm/payment?paymentId=' + executePayment.paymentId + '&PayerID=' + executePayment.PayerID+'&productID='+productID, { observe: 'response', responseType: 'json' })
      .toPromise().then((res: HttpResponse<any>) => res)
      .catch(this.handleError);
  }
}


