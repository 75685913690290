import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TokenStorageService } from 'src/app/auth/helpers/token-storage.service';
import { ShareService } from 'src/app/services/share.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-single-offer',
  templateUrl: './single-offer.component.html',
  styleUrls: ['./single-offer.component.css']
})
export class SingleOfferComponent implements OnInit {

   tokenSocialMedia:any=localStorage.getItem("tokenSocialmedia");
   offer:any;
   url:  string = environment.GATEWAY_API_URL;
   currentUser:any;
   isLoggedIn:boolean=false;
  constructor(private share :ShareService,private spinner:NgxSpinnerService,private token:TokenStorageService) { }

  ngOnInit() {
     this.getSingleOffer();
     if(this.token.getUserInfos())
     {
      this.isLoggedIn=true;
      this.currentUser = this.token.getUserInfos();
      console.log('this is the current user '+this.currentUser);

     }

  }
  getSingleOffer()
  {
    this.spinner.show()
    
     this.share.getAsingleOffer(this.tokenSocialMedia).subscribe((data:any)=>{      
         this.offer=data.data;
         this.spinner.hide()
     })
  }
  public displayPostDescription(description: string): string {
    if (description.length < 20) {
      return description;
    } else {
      return description.substr(0, 20) + '...';
    }
  }
  public displayPostAvatar(uid: string): string {
    return this.url + '/account/read/' + uid;
  }
}
