import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { StateserviceService } from "src/app/services/stateservice.service";
import { UserService } from "src/app/services/user.service";
import { environment } from "src/environments/environment";
import { TokenStorageService } from "../../auth/helpers/token-storage.service";
import * as $ from "jquery";
import { ShareService } from "src/app/services/share.service";
import { element } from "protractor";
import { Chateur } from "src/app/model/chateur";
import { MessessageSignalService } from "src/app/services/messessage-signal.service";
import { Signal } from "src/app/model/signal";
import { log } from "console";
@Component({
  selector: "app-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.css"],
})
export class MessageComponent implements OnInit {
  index: number = 0;
  url: string = environment.GATEWAY_API_URL;
  requestPatnership: any[] = [];
  acceptedRequest: any[] = [];
  usersNext: any[] = [];
  footer: Boolean;
  dialog: number = 0;
  receiver: any;
  chatteur = new Chateur();
  history: any[] = [];
  clss: any = 'min';
  idUser: string = "";
  name: string;
  id: string = this.tokenStorage.getUserInfos().id;
  recherche: boolean = false;
  constructor(
    private userService: UserService,
    private tokenStorage: TokenStorageService,
    private toastr: ToastrService,
    private stateService: StateserviceService,
    private router: Router,
    public share: ShareService,



  ) { }

  ngOnInit() {
    /* this.share.currentid.subscribe(e => {
        // //alert(e);
        this.idUser = e;
        this.getAllAcceptedDemand(e)
      }) */

    this.getAllAcceptedDemand(this.id);
  }
  SearchUser() {
    /**je contrle si je suis history ou les contacts */

    if (this.dialog === 0) {
      if (this.name == "") {
        this.getAllAcceptedDemand(this.idUser);
        this.recherche = false;

      } else {
        this.recherche = true;
        this.userService.getpartnerfilter(this.name).subscribe((data: any) => {
          console.log('------------------send------------');
          this.acceptedRequest = data.data
          console.log(this.acceptedRequest);



        })
      }

    } else if (this.dialog === 1) {
      if (this.name == "") {
        this.getHistory()

      } else {
        this.userService.getHistoryfilter(this.name).subscribe((data: any) => {
          console.log(data.data);
          this.history = data.data;

        })
      }

    }

    ////alert(this.name)


  }
  underligned(id) {
    this.clss = id
  }
  getAllAcceptedDemand(id) {

    this.userService
      .getAllAcceptedPartner(id)
      .subscribe(
        (data: any) => {

          console.log("this start");
          this.acceptedRequest = data.data;
          console.log('this is accepted demand');

          console.log(this.acceptedRequest);

          localStorage.setItem('acceptedRequest', JSON.stringify(this.acceptedRequest));
        },
        (error) => {
          this.toastr.error("Cannot get the Accepted Demand");
        }
      );
  }


  _getHistory(request) {
    this.receiver = request;
    console.log('________________the partner');

    console.log(request);

    ////alert(this.tokenStorage.getUserInfos().id)
    ////alert(this.receiver.id)
    this.userService
      .turnUnseenToSeen(this.tokenStorage.getUserInfos().id, this.receiver.id)
      .subscribe((data) => {
        ////alert("seen")
        this.userService
          .getMyconversation(this.receiver.id, this.tokenStorage.getUserInfos().id)
          .subscribe(
            (data) => {
              console.log(data);
              let utilisateur: any[] = [];

              utilisateur.push(data);
              // //alert('555')
              utilisateur.push(this.receiver);
              this.share.getChange(utilisateur);
              // //alert('ok')
            },
            (error) => {
              this.toastr.error("Cannot get the message!");
            }
          );
      });

  }

  _getMessage(request, type) {
    // this.receiver = request;
    /**jer teste  d'ou provient l'objet */
    if (type == "search") {
      this.chatteur.id = request.id;
      this.chatteur.firstName = request.receiverFirstname
      this.chatteur.lastName = request.receiverlasttname
      //this.chatteur.photo=request.photo.uid;

    } else {
      this.chatteur.lastName = request.lastName
      this.chatteur.firstName = request.firstName
      this.chatteur.imageUrl = request.imageUrl
      this.chatteur.id = request.id;
      this.chatteur.providerId = request.providerId
      this.chatteur.conversationId = request.conversationId
    }
    ////alert(this.tokenStorage.getUserInfos().id)
    ////alert(this.receiver.id)
    console.log(this.chatteur);
    // document.getElementById("chatbox").style.display = "block";
    ////alert("seen")
    this.userService
      .getMyconversation(this.tokenStorage.getUserInfos().id, this.chatteur.id)
      .subscribe(
        (data: any) => {
          console.log(data);
          let utilisateur: any[] = [];
          utilisateur.push(data);
          // //alert('555')

          utilisateur.push(this.chatteur);
          this.share.getChange(utilisateur);
          // //alert('ok')
        },
        (error) => {
          this.toastr.error("Cannot get the message!");
        }
      );


  }
  getHistory() {
    this.userService.getHistoryChat(this.tokenStorage.getUserInfos().id).subscribe((data: any) => {
      console.log(data.data);
      this.history = data.data
    })
  }
  public displayPostAvatarMiniature(uid: string): string {
    return this.url + "/account/readMiniature/" + uid;
  }

  public displayChatHistory(description: string): string {
    if (description.length < 16) {
      return description;
    } else {
      return description.substr(0, 16) + "...";
    }
  }

  public displayUsername(description: string): string {
    if (description.length > 1 && description.length < 16) {
      return description;
    } if (description.length <= 0) {
      description = "No Username";
      return description;
    }
    else {
      return description.substr(0, 16) + "...";
    }

  }


  public displayChatHistory2(description: string): string {
    if (description.length < 16) {
      return description;
    } else {
      return description.substr(0, 16) + "...";
    }
  }

  public displayUsername2(description: string): string {
    if (description.length < 20) {
      return description;
    } else {
      return description.substr(0, 20) + "...";
    }
  }




}
