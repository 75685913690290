import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslocoRootModule } from '../transloco-root.module';
import { SpinnerComponent } from './spinner/spinner.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RouterModule } from '@angular/router';
import { SpinnerofferComponent } from './spinneroffer/spinneroffer.component';
import { MessageComponent } from './message/message.component';
import { NonconnecterComponent } from './nonconnecter/nonconnecter.component';
import { LeftActivityComponent } from './left-activity/left-activity.component';
import { WidgetChatComponent } from './widget-chat/widget-chat.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { WebcamModule } from 'ngx-webcam';
import { CameraComponent } from './camera/camera.component';
import { DisplayCommentComponent } from './display-comments/display-comment.component';
import { TranslateModule } from '@ngx-translate/core';
import { CKEditorModule } from 'ckeditor4-angular';
import { HeaderPartnerComponent } from './header-partner/header-partner.component';
import { HeaderGroupComponent } from './header-group/header-group.component';



/* import { DisplayAvatarComponent } from '../download/display-avatar/display-avatar.component';
import { DisplayProfileComponent } from '../download/display-profile/display-profile.component';
import { DownloadArticleComponent } from '../download/download-article/download-article.component';
import { DownloadPhotoComponent } from '../download/download-photo/download-photo.component';
import { DownloadPieceComponent } from '../download/download-piece/download-piece.component'; */



@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    SpinnerComponent,
    SpinnerofferComponent,
    MessageComponent,
    NonconnecterComponent,
    LeftActivityComponent,
    WidgetChatComponent,
    CameraComponent,
    DisplayCommentComponent,
    HeaderPartnerComponent,
    HeaderGroupComponent,

    /*  DisplayAvatarComponent,
     DisplayProfileComponent,
     DownloadArticleComponent,
     DownloadPhotoComponent,
     DownloadPieceComponent */],
  imports: [
    CommonModule,
    RouterModule,
    WebcamModule,
    AngularFontAwesomeModule,
    NgbModule,
    ReactiveFormsModule,
    TranslocoRootModule,
    FormsModule,
    NgxSpinnerModule,
    PickerModule,
    TranslateModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    HeaderPartnerComponent,
    HeaderGroupComponent,
    CommonModule,
    CKEditorModule,
    ReactiveFormsModule,
    FormsModule,
    SpinnerComponent,
    MessageComponent,
    NonconnecterComponent,
    LeftActivityComponent,
    WidgetChatComponent,
    TranslateModule
    /*  DisplayAvatarComponent,
     DisplayProfileComponent,
     DownloadArticleComponent,
     DownloadPhotoComponent,
     DownloadPieceComponent */
  ]
})
export class ShareModule { }
