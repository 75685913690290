import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { TokenStorageService } from './token-storage.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private tokenStorage: TokenStorageService, private route: Router, private authService: AuthService) { }
   
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //  const userRole = this.authService.getUserRole();
    let role: any;
    const MAIN_ROLE = 'main_role';
    let user = window.localStorage.getItem('main_role');
   
    if (user) {
      if (user === 'ADMIN') {
        role = "ADMIN";
      
      } 
      else if (user === 'EMPLOYEE') {
        role = "EMPLOYEE";
        
      }
      else {
        role = "USER";
        
      }
      if (route.data.role && route.data.role.indexOf(role) === -1) {
        //si la personne n est pas autorisé

        this.route.navigate(['/home']);



        return false;
      }
      return true
    }
    this.route.navigate(['/home']);
    return false;
  }

  canLoad(route: Route) {
   
    let url: string = route.path;
    let role: any; 
    const MAIN_R = 'main_role';
    let user = window.localStorage.getItem('main_role');  

    if (user) {
      
      if (user === 'ADMIN') {      
        role = 'ADMIN';
      } 
      else  if (user === 'EMPLOYEE') {      
        role = 'EMPLOYEE';
      }
      else {       
        role = 'USER';
      }
      if (role == 'ADMIN') {
        this.route.navigate(['/admin/admin-home']);
        return false;
      } else if (role == 'EMPLOYEE') {
        this.route.navigate(['/company/company-home']);
        return false;
      } else {

        return true;
      }

    }
    return true;
  }


}
