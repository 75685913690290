import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpRequest, HttpParams } from '@angular/common/http';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import * as $ from 'jquery';
import {Observable} from 'rxjs';
import {RestResponse} from '../auth/helpers/restResponse';
import { environment } from '../../environments/environment';
import {Notifications} from '../model/Notifications';
const url = environment.GATEWAY_API_URL;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  /* webSocket: WebSocket;
  chatMessages: ChatMessageDto[] = []; */
  webSocketEndPoint: string =environment.GATEWAY_API_URL+'/ws';
  topic: string = "/topic/greetings";
  stompClient: any;
  //newMessages = new Map();
  newMessages = new Map([ [ "A", 1 ], ]);


  constructor(private http:HttpClient){
          /*   let m= localStorage.getItem('message')
            this.newMessages=JSON.parse(m) */
}
//public stompClient;
public msg = [];
initializeWebSocketConnection(username) {
  const serverUrl = environment.GATEWAY_API_URL;
  const ws = new SockJS(serverUrl+'/chat');
  this.stompClient = Stomp.over(ws);
  const that = this;
  // tslint:disable-next-line:only-arrow-functions
  this.stompClient.connect({}, function(frame) {
    ////alert(name);
    that.stompClient.subscribe('/topic/messages/'+username, (response) => {
      let data = JSON.parse(response.body);
     // //alert(this.name)
        if (this.selectedUser === data.fromLogin) {
            this.render(data.message, data.fromLogin);
        } else {
            // console.log(this.newMessages.size());
             let newMessage = new Map();
             newMessage.set(data.fromLogin, data.message);
            $('#userNameAppender_' + data.fromLogin).append('<span id="newMessage_' + data.fromLogin + '" style="color: red">+1</span>');
        }
    });
  });
}

/**initializeNotificationWebSocketConnection() {
  const serverUrl = environment.GATEWAY_API_URL;
  const ws = new SockJS(serverUrl+'/chat');
  this.stompClient = Stomp.over(ws);
  const that = this;

  this.stompClient.connect({}, function(frame) {

    that.stompClient.subscribe('/topic/notification/', (response) => {
      let data = JSON.parse(response.body);
      that.stompClient.send(
        '/chat/notify')
      });
  });
}*/

/*initializeNotificationWebSocketConnection() {
  const serverUrl = environment.GATEWAY_API_UR;
  const ws = new SockJS(serverUrl+'/chat');
  this.stompClient = Stomp.over(ws);
  const that = this;

  this.stompClient.connect({}, function(frame) {

    //alert("okookoko")
    that.stompClient.subscribe('/topic/notification', (response) => {
      //alert("read notif");
      let data = JSON.parse(response.body);
      console.log(data);

     });
  });


}*/

initializeNotificationWebSocketConnection() {
  const serverUrl = environment.GATEWAY_API_URL;
  const ws = new SockJS(serverUrl+'/chat');
  this.stompClient = Stomp.over(ws);
  const that = this;
  this.stompClient.connect({}, function(frame) {
   // //alert("okookoko")
    that.stompClient.subscribe('/topic/notification', (response) => {
     // //alert("read notif");
      let data = JSON.parse(response.body);
      console.log(data);

     });
  });

}

//c pas la bonne urlgi
sendMessage(from,message,sendUser) {

   this.stompClient.send(
  '/app/chat/'
 + sendUser,
  {}, JSON.stringify({
    fromLogin: from,
    message: message
}));
}

  fetchAll(): Observable<RestResponse>{
    return this.http.get<RestResponse>(url+"/chat/fetchAllUsers");

  }
  registration(name)
  {
    return this.http.get(environment.GATEWAY_API_UR +'/chat/registration/'+name);
  }


   //retourne la liste des notifications de l'ensemble des groupes auquels appartient un user (quelles soient vue ou pas)
   notificationList(enumNotifType: string, userId:string): Observable<RestResponse> {
    let params = new HttpParams();
    params = params.append('enumNotifType', enumNotifType);
    params = params.append('userId', userId);
    return this.http.get<RestResponse>(environment.GATEWAY_API_URL+'/chat/notificationList', {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: params
    })
  }
 //liste des notifications "non vues" par le user
 unseenNotificationLists(enumNotifState: string, enumNotifType: string, userId:string): Observable<RestResponse> {
  let params = new HttpParams();
  params = params.append('enumNotifState', enumNotifState);
  params = params.append('enumNotifType', enumNotifType);
  params = params.append('userId', userId);
  return this.http.get<RestResponse>(environment.GATEWAY_API_URL+'/chat/unseenNotificationList', {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    params: params
  })
}


  //transformer une notification (celle sur la quelle on clique actuellement) non vues en vues
   unseenToSeen(userId:string, notificationId:string): Observable<RestResponse> {
    let params = new HttpParams();
    params = params.append('notificationId', notificationId);
    params = params.append('userId', userId);
    return this.http.get<RestResponse>(environment.GATEWAY_API_URL+'/chat/unseenToSeen', {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: params
    })
  }


  //transformer une notification (celle sur la quelle on clique actuellement) non vues en vues d'une entreprise
  companyUnseenToSeen(companyId:string, notificationId:string): Observable<RestResponse> {
    let params = new HttpParams();
    params = params.append('notificationId', notificationId);
    params = params.append('companyId', companyId);
    return this.http.get<RestResponse>(environment.GATEWAY_API_URL+'/chat/companyUnseenToSeen', {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: params
    })
  }

  //transformer toutes les notifications non vues en vues
  generalUnseenToSeen(enumNotifType:string, userId:string): Observable<RestResponse> {
    let params = new HttpParams();
    params = params.append('enumNotifType', enumNotifType);
    params = params.append('userId', userId);
    return this.http.get<RestResponse>(environment.GATEWAY_API_URL+'/chat/generalUnseenToSeen', {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: params
    })

  }
  getNotifs(pageNo:any, pageSize: any, userId:string){
    let params = new HttpParams();
    params = params.append('pageNo', pageNo);
    params = params.append('pageSize', pageSize);
    params = params.append('userId', userId);
      return this.http.get<RestResponse>(environment.GATEWAY_API_URL+'/company/myGroupNotifications', {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params:params

    })
  }

}
