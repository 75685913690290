import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { ShareModule } from '../share/share.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslocoRootModule } from '../transloco-root.module';
import { DisplayCommentComponent } from './display-comments/display-comment.component';
import { NgxSocialShareModule } from 'ngx-social-share';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
// --- import { CKEditorModule } from 'ng2-ckeditor';
import { SingleOfferComponent } from './single-offer/single-offer.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { AppModule } from '../app.module';
import { SafeHtmlPipe } from '../utilities/SafeHtmlPipe';
//import { AuthInterceptor } from '../auth/helpers/auth-interceptor';
//import { HttpClient, HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    HomeComponent, SafeHtmlPipe,
    DisplayCommentComponent,
    SingleOfferComponent
  ],
  /* providers: [
    NgxSpinnerService
  ], */
  exports: [
    SafeHtmlPipe
  ],
  imports: [
    NgxSocialShareModule,
    ReactiveFormsModule,
    AngularFontAwesomeModule,
    HomeRoutingModule,
    ShareModule,
    HttpClientModule,
    CommonModule

  ],
  providers: [NgxSpinnerService,],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HomeModule { }
