import { Injectable, EventEmitter } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { TokenStorageService } from "../auth/helpers/token-storage.service";
import { Signal } from "../model/signal";

@Injectable({
  providedIn: 'root'
})
export class GroupesignalService {

  private hubConnection: signalR.HubConnection;
  signalReceived = new EventEmitter<Signal>();
  //groupSelected = JSON.parse(localStorage.getItem('groupSelected'));
  constructor(private userInfos: TokenStorageService) {
    this.buildConnection();
    this.startConnection();
  }

  private buildConnection() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(
        "https://notification.ushareandwin.com/notificationhub" /* ,{
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      } */
      ) //use your api adress here and make sure you use right hub name.
      .build();
  }

  private startConnection() {
    this.hubConnection
      .start()
      .then(() => {
        if (this.userInfos.getUserInfos()) {
          let groupID = this.userInfos.getUserInfos().myGroupIds;
          console.log(groupID);

          console.log("____________________end_______");

          console.log("Connection Started...");
          console.log("join group");
          groupID.forEach(element => {
            this.joinGroup(element);

          });
          this.registerSignalEvents();
        }

      })
      .catch((err) => {
        console.log("Error while starting connection: " + err);
        let that = this;
        //if you get error try to start connection again after 3 seconds.
        setTimeout(function () {
          that.startConnection();
        }, 3000);
      });
  }

  private joinGroup(referenceId: string) {
    console.log("connection to : " + referenceId);
    try {
      this.hubConnection.invoke("JoinNotification", referenceId);
      console.log("connected to referenceId ", referenceId);
    } catch (error) {
      console.log(error);
    }
  }

  private registerSignalEvents() {
    this.hubConnection.on("ReceiveNotification", (data: Signal) => {
      console.log(data);

      this.signalReceived.emit(data);
    });
  }
}
