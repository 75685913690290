import { Injectable, TemplateRef } from '@angular/core';
// import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {BehaviorSubject, Observable, Observer, Subject} from 'rxjs';
import {RestResponse} from '../auth/helpers/restResponse';
import { environment } from '../../environments/environment';
import {Company} from '../model/Company';
import { Employee } from '../model/Employee';
import { Offer } from '../model/Offer';
import { Comment } from '../model/Comment';
import { v5 as uuidv5 } from 'uuid';
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpEvent, HttpRequest, HttpParams} from '@angular/common/http';
import {ResponseData} from '../model/ResponseData';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
// export const commentsUrlByOffer =  environment.COMPANY_BASEURL+'/company/comments';
export const companyOffersUrl = environment.COMPANY_BASEURL + '/company/offers/';
export const createCompanyOffersUrl = environment.COMPANY_BASEURL + '/company/offer/create';
export const createCompanyEmployeeUrl = environment.COMPANY_BASEURL + '/company/employee/create';
export const companyEmployeesUrl = environment.COMPANY_BASEURL + '/company/employees/';
export const offerCommentsUrl = environment.COMPANY_BASEURL + '/company/comments';
export const createOfferCommentsUrl = environment.COMPANY_BASEURL + '/company/offer/comment';
export const createOfferViewsUrl = environment.COMPANY_BASEURL + '/company/view/create';
export const createOfferViewsShareUrl = environment.COMPANY_BASEURL + '/company/viewshare/create';
export const  shareOfferUrl = environment.COMPANY_BASEURL + '/company/offer/share';
export const  saveOfferUrl = environment.COMPANY_BASEURL + '/company/offer/savedOffer';
export const createOfferShareUrl = environment.COMPANY_BASEURL + '/company/view/createShare';

// export const  saveOffer = environment.COMPANY_BASEURL + '/company/offer/savedOffer';



@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  toasts: any[] = [];
  companiesUrl = environment.COMPANY_BASEURL + '/company/page';
  offersUrl = environment.COMPANY_BASEURL + '/company/offer/page';
  // commentNumberUrl = environment.COMPANY_BASEURL+'/company/commentNum/';
  offersDtoUrl = environment.COMPANY_BASEURL + '/company/offer/pageV2';
  companyOffersUrlPaginated = environment.COMPANY_BASEURL + '/company/offersInCompanyPage';
  commentOffersUrlPaginated = environment.COMPANY_BASEURL + '/company/offerCommentInOfferPage';
  viewOffersUrlPaginated = environment.COMPANY_BASEURL + '/company/offerViewInOfferPage';
  commentsUrl = environment.COMPANY_BASEURL + '/company/comment/page';
 // commentNumberUrl = environment.COMPANY_BASEURL + '/company/commentNum/';
  deleteCommentUrl = environment.COMPANY_BASEURL + '/company/comment/delete';
  viewsUrl = environment.COMPANY_BASEURL + '/company/view/page';
  singleCompanyUrl = environment.COMPANY_BASEURL + '/company';
  singleCompanyOfferUrl = environment.COMPANY_BASEURL + '/company/offer';
  commentsUrlByOffer = environment.COMPANY_BASEURL + '/company/comments';
  viewsUrlByOffer = environment.COMPANY_BASEURL + '/company/views';
  viewsByOffer = environment.COMPANY_BASEURL + '/company/viewsByOffer';
  companyEmployee = environment.COMPANY_BASEURL + '/company/employees';
  singleCompanyEmployee = environment.COMPANY_BASEURL + '/company/employee';
  singleCompanyEmploye = environment.COMPANY_BASEURL + '/company/employe';
  companiesEmployeePaginate = environment.COMPANY_BASEURL + '/company/employeesInCompanyPage';
  offerCreateUrl = environment.COMPANY_BASEURL + '/company/offer/create';
  offerUpdateUrl = environment.COMPANY_BASEURL + '/company/offer/update';
  singleOfferUrl = environment.COMPANY_BASEURL + '/company/offer';
  getOffersEmoji = environment.COMPANY_BASEURL + '/company/viewPerType/page';
  userLoyaltyUrl = environment.COMPANY_BASEURL + '/company/offer/loyaltypoint';
  /* filtre page accueil*/
  offerFilter = environment.COMPANY_BASEURL + '/company/account/filterMyOffers/page/';
  

  cmtObservable$: Observable<any>;
  private _cmtObserver: Observer<any>;
  notifyOffer(cmt: Comment) {
    this._cmtObserver.next(cmt);
  }


  constructor(private http: HttpClient) {
    this.cmtObservable$ = new Observable<any>(observer => {
      this._cmtObserver = observer;
    }).pipe();
  }



  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({textOrTpl, ...options});
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
  getfilter(filter, pagesize: any, pageno: any, sortby: string) {
    return this.http.post<RestResponse>(this.offerFilter + pageno + '/' + pagesize + '/' + sortby, filter);
  }

  getCompanies(pagesize: any, pageno: any, sortby: string): Observable<RestResponse> {
    let params = new HttpParams();
    params = params.append('pageSize', pagesize);
    params = params.append('pageno', pageno);
    params = params.append('sortby', sortby);
    return this.http.get<RestResponse>(this.companiesUrl, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params
    });
  }

  getOffers(pagesize: any, pageno: any, sortby: string): Observable<RestResponse> {
    let params = new HttpParams();
    params = params.append('pageSize', pagesize);
    params = params.append('pageno', pageno);
    params = params.append('sortby', sortby);
    return this.http.get<RestResponse>(this.offersDtoUrl, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params
    });
  }
  getOffersAdmin(pagesize: any, pageno: any, sortby: string): Observable<RestResponse> {
    let params = new HttpParams();
    params = params.append('pageSize', pagesize);
    params = params.append('pageno', pageno);
    params = params.append('sortby', sortby);
    return this.http.get<RestResponse>(this.offersUrl, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params
    });
  }

  /* getCommentNumber(offerId:string): Observable<RestResponse> {
       return this.http.get<RestResponse>(this.commentNumberUrl+offerId);
  } */

  getLike(id, flage): Observable<RestResponse> {

    let params = new HttpParams();
    params = params.append('idOffer', id);
    params = params.append('flag', flage);
    // params = params.append('',)
    return this.http.get<RestResponse>(this.getOffersEmoji, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params
    });


  }

  shareOffers(media, productId, userId, token): Observable<RestResponse> {
    let params = new HttpParams();
    params = params.append('media', media);
    params = params.append('productId', productId);
    params = params.append('userId', userId);
    params = params.append('token', token);
    return this.http.get<RestResponse>(shareOfferUrl, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params

    });
  }
  /* getCommentNumber(offerId:string): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.commentNumberUrl+offerId);
} */

  saveOffers(offerId, userId, token): Observable<RestResponse> {
    ////// sauvegarder les offres
    let params = new HttpParams();
    params = params.append('offerId', offerId);
    params = params.append('userId', userId);
    return this.http.get<RestResponse>(saveOfferUrl, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params
    });

  }

  sharingToken(): string {
    const MY_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341';
    // ⇨ '630eb68f-e0fa-5ecc-887a-7c7a62614681'
    const shareToken: string = uuidv5(new Date().getMilliseconds() + ' ' + 'hello', MY_NAMESPACE);
    return shareToken;
  }

  /*
   getCompanyOffers(pagesize: any, pageno: any, sortby: string, companyId: string): Observable<RestResponse> {
    let params = new HttpParams();
    params = params.append('pageSize', pagesize);
    params = params.append('pageno', pageno);
    params = params.append('sortby', sortby);
    return this.http.get<RestResponse>(companyOffersUrl + companyId, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: params
    })
  }*/

  getCompanyOffers(companyId: string): Observable<RestResponse> {
    // let params = new HttpParams();
    return this.http.get<RestResponse>(companyOffersUrl + companyId, httpOptions);
  }

  /**
   * Get Paginated company offers
   * @param companyId
   * @param pageSize
   * @param pageNo
   * @param sortBy
   */
  getCompanyOffersPaginated(companyId: string, pageSize: number, pageNo: number, sortBy: string): Observable<ResponseData> {
    let params1 = new HttpParams();
    params1 = params1.append('companyId', companyId);
    params1 = params1.append('pageSize', pageSize + '');
    params1 = params1.append('pageno', pageNo + '');
    params1 = params1.append('sortby', sortBy);
    return this.http.get<ResponseData>(this.companyOffersUrlPaginated, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: params1
    });
  }

  getCommentOffersPaginated(offerId: string, pageSize: number, pageNo: number, sortBy: string): Observable<ResponseData> {
    let params1 = new HttpParams();
    params1 = params1.append('pageSize', pageSize + '');
    params1 = params1.append('pageno', pageNo + '');
    params1 = params1.append('sortby', sortBy);
    params1 = params1.append('offerId', offerId);
    return this.http.get<ResponseData>(this.commentOffersUrlPaginated, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: params1
    });
  }

  getViewOffersPaginated(offerId: string, pageSize: number, pageNo: number, sortBy: string): Observable<ResponseData> {
    let params1 = new HttpParams();
    params1 = params1.append('pageSize', pageSize + '');
    params1 = params1.append('pageno', pageNo + '');
    params1 = params1.append('sortby', sortBy);
    params1 = params1.append('offerId', offerId);
    return this.http.get<ResponseData>(this.viewOffersUrlPaginated, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: params1
    });
  }

  getCompanyEmployees(companyId: string): Observable<RestResponse> {
    const params = new HttpParams();

    return this.http.get<RestResponse>(companyEmployeesUrl + companyId, httpOptions);
  }

  companyGeneralStats(): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.singleCompanyUrl + '/generalStat', httpOptions);
  }

  companyStats(companyId: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.singleCompanyUrl + '/companyStat/' + companyId, httpOptions);
  }

  getComments(pagesize: any, pageno: any, sortby: string): Observable<RestResponse> {
    let params = new HttpParams();
    params = params.append('pageSize', pagesize);
    params = params.append('pageno', pageno);
    params = params.append('sortby', sortby);
    return this.http.get<RestResponse>(this.commentsUrl, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params
    });
  }

  getCommentsByOffer(offerId: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.commentsUrlByOffer + '/' + offerId, httpOptions);
  }

  getCompanyAllEmployees(companyId: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.companyEmployee + '/' + companyId, httpOptions);
  }


  getViewsByOffer(offerId: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.viewsByOffer + '/' + offerId, httpOptions);
  }


  getViews(pagesize: any, pageno: any, sortby: string): Observable<RestResponse> {
    let params1 = new HttpParams();
    params1 = params1.append('pageSize', pagesize);
    params1 = params1.append('pageno', pageno);
    params1 = params1.append('sortby', sortby);
    return this.http.get<RestResponse>(this.viewsUrl, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: params1
    });
  }

  getMails(pagesize: any, pageno: any, sortby: string): Observable<RestResponse> {
    let params1 = new HttpParams();
    params1 = params1.append('pageSize', pagesize);
    params1 = params1.append('pageno', pageno);
    params1 = params1.append('sortby', sortby);
    return this.http.get<RestResponse>(this.viewsUrl, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: params1
    });
  }

  getCompany(id: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.singleCompanyUrl + '/' + id, httpOptions);
  }

  getComment(id: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.singleCompanyUrl + '/comment/' + id, httpOptions);
  }

  getOfferDtoInfo(offerId:string): Observable<ResponseData>
  {
     return this.http.get<ResponseData>(environment.serverEndpoint+'/company/offerDto/'+offerId, httpOptions)
  }

  getView(id: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.singleCompanyUrl + '/view/' + id, httpOptions);
  }

  getOffer(id: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.singleOfferUrl + '/' + id, httpOptions);
  }

  getEmployee(id: string): Observable<RestResponse> {

    return this.http.get<RestResponse>(this.singleCompanyEmployee + '/' + id, httpOptions);
  }

  getEmploye(id: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.singleCompanyEmploye + '/' + id, httpOptions);
  }

  /**
   * Gel all company's employee paginated
   * @param id
   * @param pageSize
   * @param pageNo
   * @param sortBy
   */
  getPaginateEmployee(id: string, pageSize: number, pageNo: number, sortBy: string): Observable<ResponseData> {
    let params1 = new HttpParams();
    params1 = params1.append('companyId', id);
    params1 = params1.append('pageSize', pageSize + '');
    params1 = params1.append('pageno', pageNo + '');
    params1 = params1.append('sortby', sortBy);
    return this.http.get<ResponseData>(this.companiesEmployeePaginate, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: params1
    });
  }
  // mise à jour de l'entreprise
  updateCompany(company: Company, id: string): Observable<RestResponse> {
    // console.log("from service company " +company);
    return this.http.put<RestResponse>(`${this.singleCompanyUrl}` + '/update/' + id, company, httpOptions);
  }


  deleteOffer(id: string): Observable<RestResponse> {
    return this.http.delete<RestResponse>(this.singleCompanyOfferUrl + '/delete/' + id, httpOptions);
  }

  deleteView(id: string): Observable<RestResponse> {
    return this.http.delete<RestResponse>(this.singleCompanyUrl + '/view/delete/' + id, httpOptions);
  }

  // mise à jour de l'employee à partir du nom de lentrpeise (un peu obsolete)
  updateEmployee(singleEmployee: Employee, companyName: string): Observable<RestResponse> {
    console.log('from service company ' + companyName);
    return this.http.put<RestResponse>(`${this.singleCompanyUrl}` + '/employee/update/' + companyName, singleEmployee, httpOptions);
  }

  // mise à jour de l'employee  via l'id de  l'entreprise
  updateEmployeeByCompanyId(singleEmployee: Employee, idCompany: string) {
    // console.log("from service company " +companyName);
    return this.http.put<RestResponse>(`${this.singleCompanyUrl}` + '/employee/update/' + idCompany, singleEmployee, httpOptions);
  }

  deleteCompany(id: string): Observable<RestResponse> {
    return this.http.delete<RestResponse>(this.singleCompanyUrl + '/delete/' + id, httpOptions);
  }

  deleteEmployee(id: string): Observable<RestResponse> {
    return this.http.delete<RestResponse>(this.singleCompanyEmployee + '/delete/' + id, httpOptions);
  }

  deleteComment(id: string): Observable<RestResponse> {
    return this.http.delete<RestResponse>(this.singleCompanyUrl + '/comment/delete/' + id, httpOptions);
  }


  createCompanyOffer(formData: FormData, idCompany: string, idEmployee: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('idCompany', idCompany);
    params = params.append('idEmployee', idEmployee);
    return this.http.post<any>(this.singleCompanyUrl + '/employee/create/', formData, {
      headers: new HttpHeaders({'Content-Type': 'multipart/form-data'}),
      params
    });
  }

  createCompanyEmployee(formData: FormData, idCompany: string): Observable<any> {
    return this.http.post<any>(createCompanyEmployeeUrl + '/' + idCompany, formData, {
      headers: new HttpHeaders({'Content-Type': 'multipart/form-data'}),
    });
  }

  /*createEmployee(this.singleEmployee, this.userEmail, this.companyName ){

  }*/
  createEmployee(singleEmployee: Employee, nameCompany: string, emailUser: string): Observable<any> {
    let params = new HttpParams();

    params = params.append('nameCompany', nameCompany);
    params = params.append('emailUser', emailUser);
    return this.http.post<any>(this.singleCompanyUrl + '/employee/create', singleEmployee, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params
    });
  }


  createCompany(company: Company): Observable<RestResponse> {
    // console.log("from service company " +company);
    //alert("company");
    return this.http.post<RestResponse>(`${this.singleCompanyUrl}` + '/create/', company, httpOptions);
  }

  getOfferComments(idOffer: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(offerCommentsUrl + '/' + idOffer);
  }

  createComment(comment: any, idOffer: string, idUser: string): Observable<RestResponse> {
    let params = new HttpParams();
    params = params.append('idOffer', idOffer);
    params = params.append('idUser', idUser);
    return this.http.post<RestResponse>(createOfferCommentsUrl, comment, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params
    });
  }

  createView(idOffer: string, idUser: string, flag: string): Observable<RestResponse> {
    let params = new HttpParams();
    params = params.append('idOffer', idOffer);
    params = params.append('idUser', idUser);
    params = params.append('flag', flag);
    // params = params.append('url', url)
    console.log(idOffer + ':' + idUser);
    return this.http.get<RestResponse>(createOfferViewsUrl, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params
    });
  }

  createShare(idOffer: string, idUser: string, flag: string): Observable<RestResponse> {
    let params = new HttpParams();
    params = params.append('idOffer', idOffer);
    params = params.append('idUser', idUser);
    params = params.append('flag', flag);
    // params = params.append('url', url)
    console.log(idOffer + ':' + idUser);
    return this.http.get<RestResponse>(createOfferShareUrl, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params
    });
  }

  createSharer(idUser, idOffer) {
      // alert("idUser: "+idUser);
      // alert("idOffer" +idOffer);
      return  this.http.get(environment.serverEndpoint + '/company/view/createShare?flag=SHARE&idOffer=' + idOffer + '&idUser=' + idUser, httpOptions);
    }
 // https://company.ushareandwin.com/company/view/createShare?flag=SHARE&idOffer=612392b4d5e74c61ad895438&idUser=5e7a2beabffcca03f8e5578c

  createViewShare(idOffer: string, idUser: string, flag: string): Observable<RestResponse> {
    let params = new HttpParams();
    params = params.append('idOffer', idOffer);
    params = params.append('idUser', idUser);
    params = params.append('flag', flag);
    // params = params.append('url', url)
    console.log(idOffer + ':' + idUser);
    return this.http.get<RestResponse>(createOfferViewsShareUrl, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params
    });
  }

  // creation  de l'offre
  createOffer(offer: Offer, file: File): Observable<RestResponse> {
    let params = new HttpParams();
    const formData: FormData = new FormData();
    params = params.append('idCompany', offer.companyId);
    params = params.append('publisherID', offer.publisherID);
    formData.append('file', file);
    formData.append('offer', new Blob([JSON.stringify(offer)], {
      type: 'application/json'
    }));

    return this.http.post<RestResponse>(this.offerCreateUrl, formData,
      {
        params
      });

  }

  updateOffer(offer: Offer, id: string): Observable<RestResponse> {
    return this.http.put<RestResponse>(`${this.singleCompanyOfferUrl}` + '/update/' + id, offer, httpOptions);

  }

  createOffe(offer: Offer, file: File): Observable<RestResponse> {
    const formData: FormData = new FormData();

    const xhr = new XMLHttpRequest();
    formData.append('offer', new Blob([JSON.stringify(offer)],
      {
        type: 'application/json'
      }));
    formData.append('file', file);
    xhr.open('POST', this.offerCreateUrl, true);
    xhr.send(formData);

    return this.http.post<RestResponse>(this.offerCreateUrl, formData,
    );

  }

  createOffeCardView(offer: FormData, file: File): Observable<RestResponse> {
    const formData: FormData = new FormData();

    const xhr = new XMLHttpRequest();
    formData.append('offer', new Blob([JSON.stringify(offer)],
      {
        type: 'application/json'
      }));
    formData.append('file', file);
    xhr.open('POST', this.offerCreateUrl, true);
    xhr.send(formData);

    return this.http.post<RestResponse>(this.offerCreateUrl, formData,
    );

  }

  upload(offer: Offer, file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    // formData.append('id', id);

    const req = new HttpRequest('POST', 'createOfferCommentsUrl' + '/uploadPic', formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  updateUpload(file: File, id: string) {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('id', id);

    const req = new HttpRequest('PUT', `${this.singleCompanyUrl}/uploadOfferAvatar`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  /*upload(file: File, id: string): Observable<HttpEvent<any>> {
     const formData: FormData = new FormData();

     formData.append('file', file);
     formData.append('id', id);

     const req = new HttpRequest('POST', `${this.groupUrl}/uploadGroupAvatar`, formData,  {
       reportProgress: true,
       responseType: 'json'
     });

     return this.http.request(req);
   }*/

  /* uploadOfferAvatar(file: File, id: string):Observable<HttpEvent<any>> {
     let params = new HttpParams();
     const formData: FormData = new FormData();

    // params = params.append('publisherID', offer.publisherID);
     formData.append('file', file)

     params = params.append('id', id);
     return this.http.post<HttpEvent<any>>(`${this.singleCompanyUrl}/uploadOfferAvatar`,  formData,
       {
         params: params
       })

   }*/

  uploadOfferAvatar(file: File, id: string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('id', id);

    const req = new HttpRequest('POST', `${this.singleCompanyUrl}/uploadOfferAvatar`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  usersComment(userId: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(`${this.singleCompanyUrl}` + '/userComments/' + userId, httpOptions);
  }

  // creation  de l'offre
  viewPerType(userId: string, viewType: string): Observable<RestResponse> {
    let params = new HttpParams();
    // const formData: FormData = new FormData();
    params = params.append('userId', userId);
    params = params.append('viewType', viewType);
    // formData.append('file', file)
    /* formData.append('offer', new Blob([JSON.stringify(offer)], {
       type: "application/json"
     }));*/

    return this.http.get<RestResponse>(this.singleCompanyUrl + '/userViewsPerType',
      {
        params
      });

  }

  /**
   * Create an employee with existing user account
   * @param email
   * @param companyId
   */
  createEmployeeWithExistingUser(email: string, companyId: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(createCompanyEmployeeUrl + '?companyId=' + companyId + '&emailUser=' + email, httpOptions);
  }

  /**
   * On / Off employee account
   * @param employeeId
   */
  onOffEmployeeAccount(employeeId: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(environment.COMPANY_BASEURL + '/company/employee/status/' + employeeId,
      httpOptions);
  }

  /**
   * On / Off offer
   * @param offerId
   */
  onOffOffer(offerId: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(environment.COMPANY_BASEURL + '/company/offer/status/' + offerId,
      httpOptions);
  }

  /**
   * Get the name of a company throughout his id
   * @param companyId
   */
  getCompanyName(companyId: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(environment.COMPANY_BASEURL + '/company/companyName/' + companyId,
      httpOptions);
  }
  getLightCompanyList(): Observable<RestResponse> {
    return this.http.get<RestResponse>(environment.COMPANY_BASEURL + '/company/lightCompanyList',
      httpOptions);
  }

  getAllOffers(): Observable<RestResponse> {
    return this.http.get<RestResponse>(environment.COMPANY_BASEURL + '/company/allOffers');
  }

  /**
   * Get filtering entitys data using key, and backend url
   * https://ushareandwin.com:8080/account/filterUser?pageNo=xxxx&pageSize=yyyyy&key=jjjjjjjj
   * @param key
   * @param pageSize
   * @param pageNo
   * @param url
   */
  filterPaginateEntities(key: string, pageSize: number, pageNo: number, url: string): Observable<ResponseData> {
    let params1 = new HttpParams();
    params1 = params1.append('key', key);
    params1 = params1.append('pageSize', pageSize + '');
    params1 = params1.append('pageNo', pageNo + '');
    return this.http.get<ResponseData>(url, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: params1
    });
  }

  /**
   * Get filtering entitys data using key, and backend url
   * filterGroupInCompany?companyId=5e96e4217ee5b917a2a8fdfc&key=Java&pageSize=2&pageno=0
   * @param companyId
   * @param key
   * @param pageSize
   * @param pageNo
   * @param url
   */


  filterPaginateEntitiesInCompany(companyId: string, key: string, pageSize: number, pageNo: number, url: string): Observable<ResponseData> {
    let params1 = new HttpParams();
    params1 = params1.append('companyId', companyId);
    params1 = params1.append('key', key);
    params1 = params1.append('pageSize', pageSize + '');
    params1 = params1.append('pageNo', pageNo + '');
    return this.http.get<ResponseData>(url, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: params1
    });
  }
}

