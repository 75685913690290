import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {UserService} from './services/user.service';
import {TokenStorageService} from './auth/helpers/token-storage.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Recherche} from './model/recherche';
import {Subscription} from 'rxjs';
import {NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent} from 'ngx-cookieconsent';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  recherche = new Recherche();
  EmpScreen = false;
  DepScreen = false;
  closeResult: string;
  requestPatnership: any[] = [];
  acceptedRequest: any[] = [];
  totalElement: number;

  constructor(private translate: TranslateService, private titleService: Title, private ref: ChangeDetectorRef,
              private userService: UserService, private modalService: NgbModal, private ccService: NgcCookieConsentService,
              private tokenStorage: TokenStorageService, private toastr: ToastrService, private  spinner: NgxSpinnerService) {

    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');

    /*let browserLang = navigator.language.split('-')[0]; // use navigator lang if available
    browserLang = /(fr|en)/gi.test(browserLang) ? browserLang : 'fr';*/

    const browserLang = this.translate.getBrowserLang();
    // --- this.translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    // --- translate.use(browserLang);
    translate.use('en');

    // set page title
    const translatePipe = new TranslatePipe(translate, ref);
    titleService.setTitle(translatePipe.transform('APP.TITLE'));
    this.translate.get('APP.TITLE').subscribe((translatedValue: string) => {
      titleService.setTitle(translatedValue);
    });

    this.translate.get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
      .subscribe(data => {

        this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
        // Override default messages with the translated ones
        this.ccService.getConfig().content.header = data['cookie.header'];
        this.ccService.getConfig().content.message = data['cookie.message'];
        this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
        this.ccService.getConfig().content.allow = data['cookie.allow'];
        this.ccService.getConfig().content.deny = data['cookie.deny'];
        this.ccService.getConfig().content.link = data['cookie.link'];
        this.ccService.getConfig().content.policy = data['cookie.policy'];

        this.ccService.destroy(); // remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      });
  }
  // title = 'findandsee';

  notifPending($event) {
    this.getAllPendingDemand();
  }
  notifAccept($event) {
    this.getAllAcceptedDemand();
  }
  getAllPendingDemand() {
    this.userService
      .patnerShip(this.tokenStorage.getUserInfos().id)
      .subscribe((data: any) => {
        console.log(data.data);

        this.requestPatnership = data.data;
      });
  }
  getAllAcceptedDemand() {
    this.userService.getAllAcceptedPartner(this.tokenStorage.getUserInfos().id)
      .subscribe((data: any) => {
        this.totalElement = JSON.parse(localStorage.getItem('totalElement'));
        this.acceptedRequest = data.data;
        this.spinner.hide();
      });
  }
  receiveOffer($event) {
    this.recherche = $event;
    // this.open(content);
  }
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {});
    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {});
    this.initializeSubscription = this.ccService.initialize$.subscribe((event: NgcInitializeEvent) => {});
    this.statusChangeSubscription = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {});
    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(() => {});
    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe((event: NgcNoCookieLawEvent) => {});
  }

  ngOnDestroy() {
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
}
