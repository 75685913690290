import { Injectable } from '@angular/core';
import {User} from '../model/user';
import { Observable, throwError } from 'rxjs';
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Identifiers } from '@angular/compiler';
import {RestResponse} from '../auth/helpers/restResponse';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})

export class FileUploadService {

  baseURL = "http://localhost:4000/api";
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  url: string = environment.GATEWAY_API_URL+"/upload";
  ProfilUrl: string = environment.GATEWAY_API_URL+"/account";
  ProfilUrlEmployee: string = environment.GATEWAY_API_URL+"/company";
  //profilUrl: string = environment.GATEWAY_API_URL+"/upload-profile-pic";
  fds =new FormData();
  selectedFile : File = null;
  files: [] = [];
  constructor(private http: HttpClient) { }

  // Get Users
  getUsers() {
    return this.http.get(this.baseURL)
  }
  
  uploadProfil(id:string, email:string, selectedFile:File):Observable<HttpEvent<{}>>{
    const fd: FormData = new FormData();
    
    fd.append('id', id);
    fd.append('email', email);
    fd.append('image', selectedFile);
    const req = new HttpRequest('POST', `${this.ProfilUrl}/uploadProfilPic`,fd, {
      reportProgress: true,
      responseType: 'text',
    });
    return this.http.request(req);
   // return  this.http.post<any>(this.url, fd);
  }

 
  
  // Retrouver les articles d'un utilisateur
  /*getArticlesAdherent(id: number): Observable<Article[]> {
    return this.http.get<Article[]>(this.url + "/adherent/articles/" + id, httpOptions);
  }*/
  getMyProfilePicture(uid:string):Observable<any> {
    return this.http.get<any>(`${this.ProfilUrl}/read/`+uid, httpOptions);
  }

  getAllUserPictures(userId:string):Observable<RestResponse> {
    return this.http.get<RestResponse>(`${this.ProfilUrl}/myMedia/`+userId, httpOptions);
  }

 /*uploadUsersPicture(userId:string):Observable<RestResponse> {
    return this.http.get<RestResponse>(`${this.ProfilUrl}/uploadMyMedia/`+userId, httpOptions);
  }*/

  uploadUsersPicture(file: File, id:string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('id', id);

    const req = new HttpRequest('POST', `${this.ProfilUrl}/uploadMyMedia`, formData,  {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  upload(file: File, id:string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('id', id);

    const req = new HttpRequest('POST', `${this.ProfilUrl}/uploadProfilPic`, formData,  {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  uploadEmployee(file: File, id:string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('id', id);

    const req = new HttpRequest('POST', `${this.ProfilUrlEmployee}/uploadProfilPic`, formData,  {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  updateUpload(file: File, id:string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('id', id);

    const req = new HttpRequest('POST', `${this.ProfilUrl}/updateProfilPic`, formData,  {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  updateUploadEmployee(file: File, id:string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('id', id);

    const req = new HttpRequest('POST', `${this.ProfilUrlEmployee}/updateProfilPic`, formData,  {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }
  

  // Charger la photo d'un adhérent
  /*uploadPhotoAdherent(file: File, id:number): Observable<HttpEvent<{}>>{
    const formdata: FormData = new FormData();

    formdata.append('file', file);
    formdata.append('id', id.toString());
    
    const req = new HttpRequest('POST', this.url+ "/commun/files/upload/photo",formdata, {
      reportProgress: true,
      responseType: 'text',
    });
    return this.http.request(req);
  }*/

  // Create User
  addUser(profileImage: File): Observable<any> {
    var formData: any = new FormData();
    //formData.append("name", name);
    formData.append("avatar", profileImage);
    return this.http.post<User>(`${this.baseURL}/create-user`, formData, {
      reportProgress: true,
      observe: 'events'
    })
  }

 

  // Error handling 
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

}