import { NgModule } from '@angular/core';
import { HomeComponent } from './home.component';
import { Routes, RouterModule } from '@angular/router';
import { SingleOfferComponent } from './single-offer/single-offer.component';

const routes : Routes = [
  {path:'', component:HomeComponent},
  { path:'singleOffer',component:SingleOfferComponent}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class HomeRoutingModule { }
