export class UpdateAccount {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    address: string;
    description:string;
    city:string;
    sendNewsletter:boolean;
    country:string;
    phone : string;
    gender:string;
    profession:string; 
   }