import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpEvent, HttpRequest, HttpParams} from '@angular/common/http';
import { ResponseData } from '../model/ResponseData';
import { environment } from '../../environments/environment';
//https://backend.ushareandwin.com
//export const companyOffersUrl = environment.GATEWAY_API_URL + '/account/partners/userLightFilter';
@Injectable({
  providedIn: 'root'
})
export class PartnerServiceService {
  
  constructor(private  http:HttpClient) { }

  filterPartner(name:string, userId:string)
  {
    let url = 'https://backend.ushareandwin.com/account/partners/userLightFilter';
    let params1 = new HttpParams();
    params1 = params1.append('key', name);
    params1 = params1.append('receiverId', userId);
    params1 = params1.append('sendeId', userId);
    
    return this.http.get<any>(url, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: params1
    });
  }
}
