import { Injectable } from '@angular/core';
import { AuthResponse } from './authresponse';

const TOKEN_KEY = 'AuthToken';
const USERNAME_KEY = 'AuthUsername';
const AUTHORITIES_KEY = 'AuthAuthorities';
const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const EXPIRY_DURATION_KEY = 'expiryDuration';
const USER_NAME_KEY = 'username';
const TOKEN_TYPE_KEY = 'tokenType';


@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  private readonly USER_INFOS = 'USER_INFOS';

  private roles: Array<string> = [];
  constructor() {}

  public saveAccessToken(accessToken: string) {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    window.sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  }

  public getAccessToken(): string {
    return sessionStorage.getItem(ACCESS_TOKEN_KEY);
  }

  public get_Token():string
  {
     return  localStorage.getItem(ACCESS_TOKEN_KEY)
  }
  public saveRefreshToken(refreshToken: string) {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    window.sessionStorage.removeItem(REFRESH_TOKEN_KEY);
    window.sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }

  public saveExpiryDuration(expiryDuration: number) {
    localStorage.removeItem(EXPIRY_DURATION_KEY);
    localStorage.setItem(EXPIRY_DURATION_KEY, JSON.stringify(expiryDuration));
    window.sessionStorage.removeItem(EXPIRY_DURATION_KEY);
    window.sessionStorage.setItem(EXPIRY_DURATION_KEY, JSON.stringify(expiryDuration));
  }

  public getExpiryDuration(): number {
    return +(sessionStorage.getItem(EXPIRY_DURATION_KEY));
  }

  public saveToken(token: string) {
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUsername(username: string) {
    sessionStorage.removeItem(USERNAME_KEY);
    sessionStorage.setItem(USERNAME_KEY, username);
    localStorage.removeItem(USERNAME_KEY);
    localStorage.setItem(USERNAME_KEY, username);
  }

  public getUsername(): string {
    return sessionStorage.getItem(USERNAME_KEY);
  }
  public get_UserNAme():string
  {
    return  localStorage.getItem(USERNAME_KEY);
  }
  public saveAuthorities(authorities: string[]) {
    sessionStorage.removeItem(AUTHORITIES_KEY);
    sessionStorage.setItem(AUTHORITIES_KEY, JSON.stringify(authorities));
    localStorage.removeItem(AUTHORITIES_KEY)
    localStorage.setItem(AUTHORITIES_KEY, JSON.stringify(authorities));
  }

  public getAuthorities(): string[] {
    this.roles = [];

    if (sessionStorage.getItem(TOKEN_KEY)) {
      JSON.parse(sessionStorage.getItem(AUTHORITIES_KEY)).forEach(authority => {
        this.roles.push(authority.authority);
      });
    }
    return this.roles;
  }
  public saveTokenType(tokenType: string) {
    localStorage.removeItem(TOKEN_TYPE_KEY);
    localStorage.setItem(TOKEN_TYPE_KEY, tokenType);
    window.sessionStorage.removeItem(TOKEN_TYPE_KEY);
    window.sessionStorage.setItem(TOKEN_TYPE_KEY, tokenType);
  }
  // Sauvegarde de toute une réponse issue de l'établissement avec succès de la connexion
  /*public saveAuthResponse(authResponse: AuthResponse){
    this.saveToken(authResponse.accessToken);
    this.saveUsername(authResponse.username);
    this.saveAuthorities(authResponse.authorities);
  }*/
  // Sauvegarde de toute une réponse issue de l'établissement avec succès de la connexion
  public saveAuthResponse(authResponse: AuthResponse) {
    this.saveAccessToken(authResponse.accessToken);
    this.saveRefreshToken(authResponse.refreshToken);
    this.saveTokenType(authResponse.tokenType);
    this.saveExpiryDuration(authResponse.expiryDuration);
    this.saveUsername(authResponse.username);
    this.saveAuthorities(authResponse.authorities);
    /*
    this.saveAccessToken("eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI1ZjIwYWE3ZjI5MTRmZTIzYzczMzI4OTYiLCJpYXQiOjE1OTgxODQ2NzUsImV4cCI6MTU5ODI3MTA3NX0.yGDv-lbtAKDTSwCCOmjyKCBw4ltxNpTM7eIHMwX07xs");
    this.saveRefreshToken("eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI1ZjIwYWE3ZjI5MTRmZTIzYzczMzI4OTYiLCJleHAiOjEwMjM4MTI4NzQ1LCJpYXQiOjE1OTgxMjg3NDV9.L4SG9i98WyCj3z578DWB9zW0Qgr_LBMN7VliHOnrSsQ");
    this.saveTokenType("Bearer");
    this.saveExpiryDuration(80000);
    this.saveUsername("chimi1990@yahoo.fr");
    this.saveAuthorities(["USER"]);
    */
  }

  // Déconnexion d'un utilisateur et nettoyage de la session
  signOut() {
    this.doLogoutUser();
    this.removeTokens();
  }

  doLogoutUser() {
    this.roles = [];
    sessionStorage.removeItem(USERNAME_KEY);
    sessionStorage.removeItem(AUTHORITIES_KEY);
    sessionStorage.removeItem(USER_NAME_KEY);
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('email');
    localStorage.removeItem('address');
    localStorage.removeItem("main_role");
    localStorage.removeItem('social');
    localStorage.removeItem(this.USER_INFOS);
  }

  removeTokens() {
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem(ACCESS_TOKEN_KEY);
    sessionStorage.removeItem(REFRESH_TOKEN_KEY);
    sessionStorage.removeItem(EXPIRY_DURATION_KEY);
    sessionStorage.removeItem(TOKEN_TYPE_KEY);
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }

  public saveUserInformations(firstName: any, lastName: any, email: any, address: any) {
    localStorage.setItem('firstName', firstName);
    localStorage.setItem('lastName', lastName);
    localStorage.setItem('email', email);
    localStorage.setItem('address', address);
  }

  public getUserInfos() {
    return JSON.parse(localStorage.getItem(this.USER_INFOS));
  }

  public saveUserInfos(user: any) {
    localStorage.setItem(this.USER_INFOS, JSON.stringify(user));
  }

  public getTokenLocalStorage()
  {
     return JSON.stringify(localStorage.getItem(''))
  }
}
