import {File} from './file';
export class User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  address: string;
  // data:any;
  qualification: string;
  groupNotification: number;
  activitiesNotification: number;
  generalNotification: number;
  birthDate: Date;
  description: string;
  emailVerified: boolean;
  emailVerifiedAt: Date;
  enable: boolean;
  changeEnabledAt: Date;
  refreshToken: string;
  providerId: string;
  provider: EnumProvider;
  imageUrl: string;
  userName: string;
  city: string;
  country: string;
  phone: string;
  state: string;
  gender: string;
  file: File;
  photo: any;
  authorities: string[];
  sendNewsletter: boolean;
  enumRegistrationState: EnumRegistrationState;

}
