import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ShareModule } from './share/share.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import modules necessary for translation
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslocoRootModule } from './transloco-root.module';
//import {HTTP_INTERCEPTORS} from '@angular/common/http';

import { ToastrModule } from 'ngx-toastr';
import { ToastsContainer } from './admin/group/toasts-container.component';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PickerModule, EmojiFrequentlyService, EmojiSearch } from '@ctrl/ngx-emoji-mart';
import { EmojiService } from '@ctrl/ngx-emoji-mart/ngx-emoji';
// --- import { CKEditorModule } from 'ng2-ckeditor';
import { CKEditorModule } from 'ckeditor4-angular';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { HomeModule } from './home/home.module';
import { NgxSocialShareModule } from 'ngx-social-share';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HomeRoutingModule } from './home/home-routing.module';
import { PaymentService } from './services/payment.service';
import { JWTInterceptor } from './_helpers/jwt.interceptor';
import { CommonModule } from '@angular/common';

// import { ShareIconsModule} from 'ngx-sharebuttons/buttons';
// import { ToastsContainer } from '../../findandsee-likes/findandsee-client/src/app/admin/company/toasts-container.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    // domain: window.location.hostname // to make the domain dynamic
  },
  palette: {
    popup: {
      background: '#0069d9'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'edgeless',
  type: 'opt-out'
};

@NgModule({
  declarations: [
    AppComponent,
    ToastsContainer
  ],
  imports: [
    CKEditorModule,
    FormsModule,
    HomeModule,
    AppRoutingModule,
    ChartsModule,
    BrowserModule,
    NgxPaginationModule,
    NgbModule,
    ShareModule,
    PickerModule,
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] }, isolate: true
    }),
    TranslocoRootModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    CommonModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true }, ThemeService, EmojiFrequentlyService,
    EmojiSearch, EmojiService, { provide: LOCALE_ID, useValue: 'en' }, TranslateService, PaymentService],
  /*schemas: [
    NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA
  ],*/
  bootstrap: [AppComponent],

})
export class AppModule { }
