import { Injectable, TemplateRef } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpRequest,
  HttpParams,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { TokenStorageService } from "../auth/helpers/token-storage.service";
import { UpdateAccount } from "../model/UpdateAccount";
import { RestResponse } from "../auth/helpers/restResponse";
import { User } from "../model/user";
import { Login } from "../model/login";
import { environment } from "../../environments/environment";
import { ResponseData } from "../model/ResponseData";
import { Location } from "@angular/common";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class UserService {
  toasts: any[] = [];
  jtn = localStorage.getItem("refresh");
  httpOption = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + this.jtn,
    }),
  };
  private loginUrl = environment.GATEWAY_API_URL + "/auth/login";
  private signupUrl = environment.GATEWAY_API_URL + "/account/register";
  private Url = environment.GATEWAY_API_URL + "/account/";
  private url = environment.GATEWAY_API_URL + "/account/";
  private Url2 = environment.USER_BASEURL + "/account/";
  private userUrl = environment.GATEWAY_API_URL + "/auth/me";
  private usersUrl = environment.GATEWAY_API_URL + "/auth/mes";
  usersPageUrl = environment.USER_BASEURL + "/account/user/page";
  private allUsersUrl = environment.USER_BASEURL + "/account/users";
  subscribersPageUrl = environment.GROUP_BASEURL + "/group/subscriber/page";
  userLoyaltyUrl = environment.COMPANY_BASEURL + "/company/offer/loyaltypoint/";

  /***partnership */
  listusers = environment.PARTNERSHIP_URL + "/account/partners/userLightPage";
  partnership = environment.PARTNERSHIP_URL + "/account/addPartner";
  myPartnerId = environment.PARTNERSHIP_URL + "/account/myPartnershipId";
  demandeEnCoursP =
    environment.PARTNERSHIP_URL + "/account/pendingPartnersPage";
  demandeEnCours = environment.PARTNERSHIP_URL + "/account/pendingPartners/";
  acceptedDemand = environment.PARTNERSHIP_URL + "/account/acceptedPartners/";
  acceptedDemandP =
    environment.PARTNERSHIP_URL + "/account/acceptedPartnersPage";
  acceptRequestParners = environment.PARTNERSHIP_URL + "/account/acceptPartner";
  removePartner = environment.PARTNERSHIP_URL + "/account/removePartner";
  /**url partner  */
  filterPartner = environment.PARTNERSHIP_URL + "/filterPartnership";
  filterHistory = environment.GATEWAY_API_UR + "/chat/filterPartnerHistory";
  /**DELETE */
  deleteMsg = environment.GATEWAY_API_UR + "/chat/deleteMessages"
  deleteAMsg = environment.GATEWAY_API_UR + "/chat/deleteMessage/"
  constructor(
    private http: HttpClient,
    private token: TokenStorageService,
    private location: Location
  ) {
    console.log("this is the token " + this.jtn);
  }

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }
  remove(toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  // Ajouter un User
  createUser(users: User): Observable<User> {
    return this.http.post<User>(`${this.signupUrl}`, users, httpOptions);
  }

  signUpUser(login: Login): Observable<any> {
    return this.http.post(`${this.loginUrl}`, login, httpOptions);
  }

  // Connexion d'un utilisateur
  login(username: string, password: string): Observable<User> {
    return this.http.post<User>(
      this.loginUrl + "/auth/login",
      { username, password },
      httpOptions
    );
  }

  userLoyalty(userId: string): Observable<RestResponse> {
    // let params = new HttpParams();
    return this.http.get<RestResponse>(
      this.userLoyaltyUrl + userId,
      httpOptions
    );
  }

  // Deconnexion d'un utilisateur
  logout(): Observable<RestResponse> {
    var user: any = this.token.getUserInfos();
    console.log(this.jtn);
    this.token.signOut();
    localStorage.removeItem("refresh");
    localStorage.removeItem("id_utilisateur");
    localStorage.removeItem("main_role");
    return this.http.get<RestResponse>(
      environment.GATEWAY_API_URL + "/auth/logout/" + user["id"],
      this.httpOption
    );
  }
  /*// Lister tous les Users
  getAllAdherents(): Observable<Userr []> {
    return this.http.get<User []>(this.url + "/utilisateurs", httpOptions);
  }

  // Lister tous les Users paginees
  getAllAdherentsPage(page:number,size:number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.url + "/utilisateurs/page?page="+page+"&size="+size, httpOptions);
  }*/

  // Lister tous les codeValue suivant une valeur donnée
  getUser(username: string): Observable<User> {
    return this.http.get<User>(`${this.userUrl}` + username, httpOptions);
  }
  //je prend en entrée un userId et retourne un partnershipId
  getMyPartnershipId(senderId: string, receiverId: string): any {
    // return this.http.get(`${this.myPartnerId}`+userId, httpOptions);
    let params = new HttpParams();
    params = params.append("senderId", senderId);
    params = params.append("receiverId", receiverId);
    return this.http.get<RestResponse>(this.myPartnerId, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params,
    });
  }

  // modification du mdp
  /*updatePass(passwd:string, token: string): Observable<RestResponse> {
    //console.log(passwd+"AND"+token);
    return this.http.put<RestResponse>(`${this.Url}reset-password/`+token, passwd, httpOptions);
  }*/
  updatePass(
    newPassword: string,
    token: string,
    myType: string
  ): Observable<RestResponse> {
    let params = new HttpParams();
    params = params.append("token", token);
    params = params.append("newPassword", newPassword);
    params = params.append("myType", myType);
    return this.http.get<RestResponse>(this.url + "reset-password", {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params,
    });
    // return  this.http.post<any>(this.url, fd);
  }
  // Envoie du mail contenant le token, pour changer de mdp
  sendEmailPass(email: string): Observable<User> {
    return this.http.post<User>(
      `${this.Url}newPasswdMail/` + email,
      httpOptions
    );
  }

  // on modifie le mot de passe en se rappelant de l'ancien mdp
  updateknownedPass(
    email: string,
    oldPassword: string,
    newPassword: string
  ): Observable<RestResponse> {
    //  return this.http.post<User>(`${this.Url}newPasswdMail/` + email, httpOptions);
    let params = new HttpParams();
    params = params.append("email", email);
    params = params.append("oldPassword", oldPassword);
    params = params.append("newPassword", newPassword);

    return this.http.get<RestResponse>(this.url + "/update-password", {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params,
    });
  }

  // Retrouver les informations sur l'utilisateur connecté
  getMyAccount(): Observable<RestResponse> {
    let jetn = this.token.getAccessToken();

    // httpOptions.headers.append('Authorization','Bearer '+jetn)
    return this.http.get<RestResponse>(`${this.userUrl}`, this.httpOption);
  }

  // mise à jour profile
  updateMyAccount(
    user: UpdateAccount,
    email: string
  ): Observable<RestResponse> {
    // console.log(user);
    return this.http.put<RestResponse>(
      `${this.Url}` + "update/" + email,
      user,
      httpOptions
    );
  }

  // Retrouver les informations sur l'utilisateur connecté
  /*getMyAccountOauth(): Observable<RestResponse>{

   return this.http.get<RestResponse>(`${this.usersUrl}`, httpOptions);
 }*/

  getUsers(
    pagesize: any,
    pageno: any,
    sortby: string
  ): Observable<RestResponse> {
    let params = new HttpParams();
    params = params.append("pageSize", pagesize);
    params = params.append("pageno", pageno);
    params = params.append("sortby", sortby);
    return this.http.get<RestResponse>(this.usersPageUrl, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params,
    });
  }
  addApartner(idUser, idPartner) {
    let params = new HttpParams();
    params = params.append("hisUserId", idPartner);
    params = params.append("myUserId", idUser);
    return this.http.get<RestResponse>(this.partnership, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params,
    });
  }
  getAllUsersPatnership(id, pagesize: any, pageno: any, sortby: string): Observable<RestResponse> {
    let params = new HttpParams();
    params = params.append("pageSize", pagesize);
    params = params.append("pageno", pageno);
    params = params.append("sortby", sortby);
    params = params.append("userId", id);

    return this.http.get<RestResponse>(this.listusers, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params,
    });
  }

  getCompanySubscribers(
    pagesize: any,
    pageno: any,
    sortby: string
  ): Observable<RestResponse> {
    let params = new HttpParams();
    params = params.append("pageSize", pagesize);
    params = params.append("pageno", pageno);
    params = params.append("sortby", sortby);
    return this.http.get<RestResponse>(this.subscribersPageUrl, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params,
    });
  }

  getuser(id: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.Url2 + "user/" + id, httpOptions);
  }
  deleteUser(id: string): Observable<RestResponse> {
    return this.http.delete<RestResponse>(
      this.Url2 + "delete/" + id,
      httpOptions
    );
  }

  getAllUsers(): Observable<ResponseData> {
    return this.http.get<ResponseData>(this.allUsersUrl, httpOptions);
  }

  onOffUserAccount(userId: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(
      environment.USER_BASEURL + "/account/user/status/?userId=" + userId,
      httpOptions
    );
  }

  getUserSessionInfos(userId: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(
      environment.USER_BASEURL + "/account/sessionInfo/" + userId,
      httpOptions
    );
  }
  turnUnseenToSeen(idSender: string, idReceiver: string) {
    let params = new HttpParams();
    params = params.append("senderId", idSender);
    params = params.append("receiverId", idReceiver);
    //https://realtime.ushareandwin.com/chat/vuMessage?receiverId=5e7a2beabffcca03f8e5578c&senderId=612678ee2cd8384af580c1df
    return this.http.get<RestResponse>("https://backend.ushareandwin.com/chat/vuMessage?receiverId=" + idReceiver + "&senderId=" + idSender)
  }
  getMyconversation(idSender: string, idReceiver: string) {
    let params = new HttpParams();
    params = params.append("senderId", idSender);
    params = params.append("receiverId", idReceiver);
    return this.http.get<RestResponse>(environment.GATEWAY_API_UR + "/chat/myConversations",
      {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
        params
      })
  }
  _sendMessage(message: string, idSender: string, idReceiver: string, conversationId: string) {
    let params = new HttpParams();
    /// let conversationId = localStorage.getItem('nkwaba');
    params = params.append('conversationId', conversationId);
    params = params.append('senderId', idSender);
    params = params.append('receiverId', idReceiver);
    return this.http.post<RestResponse>(
      environment.GATEWAY_API_UR + "/chat/sendMsgTes",
      message,
      {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
        params
      }
    );
  }

  // envoi d'un message avec fichier(s)
  _sendMessageWithfile(msg: string, senderId: string, receiverId: string, file: File): Observable<RestResponse> {
    let params = new HttpParams();
    const formData: FormData = new FormData();
    params = params.append('senderId', senderId);
    params = params.append('receiverId', receiverId);
    params = params.append('msg', msg);
    formData.append('file', file);
    return this.http.post<RestResponse>(environment.GATEWAY_API_UR + "/chat/sendMsgFile", formData,
      {
        params
      });

  }

  patnerShip(id) {
    return this.http.get<RestResponse>(this.demandeEnCours + id);
  }
  getPaginationPartnership(
    id,
    pagesize: any,
    pageno: any,
    sortby: string
  ): Observable<RestResponse> {
    let params = new HttpParams();
    params = params.append("pageSize", pagesize);
    params = params.append("pageno", pageno);
    params = params.append("sortby", sortby);
    params = params.append("userId", id);
    return this.http.get<RestResponse>(this.demandeEnCoursP, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params,
    });
  }
  getPaginationAccepted(
    id,
    pagesize: any,
    pageno: any,
    sortby: string
  ): Observable<RestResponse> {
    let params = new HttpParams();
    params = params.append("pageSize", pagesize);
    params = params.append("pageno", pageno);
    params = params.append("sortby", sortby);
    params = params.append("userId", id);
    return this.http.get<RestResponse>(this.acceptedDemandP, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params,
    });
  }
  onBackClicked() {
    this.location.back();
  }
  getHistoryChat(senderId: string) {
    let params = new HttpParams();
    params = params.append("senderId", senderId);

    return this.http.get<RestResponse>(environment.GATEWAY_API_UR + "/chat/inboxMessage/" + senderId)
  }

  onFrowardClicked() {
    this.location.forward();
  }
  getAllAcceptedPartner(userId) {
    return this.http.get<RestResponse>(this.acceptedDemand + userId);
  }
  acceptRequestParner(idUser, partnerId) {
    let params = new HttpParams();
    params = params.append("hisUserId", idUser);
    params = params.append("partnerId", partnerId);
    return this.http.get<RestResponse>(this.acceptRequestParners, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params,
    });
  }
  removePartners(idUser, partnerId) {
    let params = new HttpParams();
    params = params.append("hisUserId", idUser);
    params = params.append("partnershipId", partnerId);
    return this.http.get<RestResponse>(this.removePartner, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params,
    });
  }
  /**recuperer les utilisateurs en fonction de la valeur entrée */
  getpartnerfilter(name: string) {
    let params = new HttpParams();
    params = params.append("key", name);
    params = params.append("receiverId", this.token.getUserInfos().id);
    params = params.append("senderId", this.token.getUserInfos().id);
    return this.http.get<RestResponse>(this.filterPartner, {
      //headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params,
    });
  }
  getHistoryfilter(name: string) {
    let params = new HttpParams();
    params = params.append("key", name);
    params = params.append("receiverId", this.token.getUserInfos().id);
    params = params.append("senderId", this.token.getUserInfos().id);
    return this.http.get<RestResponse>(this.filterHistory, {
      //headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params,
    });
  }
  deleteMessage(param) {

    return this.http.get<RestResponse>(this.deleteMsg + '?chatIds=' + param)
  }
  deleteMess(params) {
    return this.http.get<RestResponse>(this.deleteAMsg + params);
  }
}
