import { Component, OnInit, HostListener } from "@angular/core";
import * as $ from "jquery";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ShareService } from "src/app/services/share.service";
import { Subject, Observable } from 'rxjs';
//import {Observable} from 'rxjs/Observable';
import { WebcamImage } from 'ngx-webcam';

import { ToastrService } from "ngx-toastr";
import { UserService } from "src/app/services/user.service";
import { environment } from "src/environments/environment";
import { TokenStorageService } from "../../auth/helpers/token-storage.service";
import { MessessageSignalService } from "src/app/services/messessage-signal.service";
import { Signal } from "src/app/model/signal";
import { Message } from "src/app/model/message";

@Component({
  selector: "app-widget-chat",
  templateUrl: "./widget-chat.component.html",
  styleUrls: ["./widget-chat.component.css"],
})
export class WidgetChatComponent implements OnInit {
  public webcamImage: WebcamImage = null;
  messages: any;
  mySenderId: string;
  isLoggedIn: boolean;
  display = true;
  messageId: string[] = [];
  mess: any
  interlocuteur: any;
  load = false
  interlocuteurs: any[] = [];
  receiver: string
  url = environment.GATEWAY_API_URL;
  find = false;
  message: string = "";
  closeResult = '';
  rload: boolean;
  showEmojiPicker = false;
  nbretof: number = 0;
  nbrefile: number = 0
  fichierJoint: any;
  displaymenu = false;
  messageSenderId: string = '';
  interlocuteurId: string = "";
  fichiers: any
  idMessage: string = '';
  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ]
  set = 'twitter';
  menu: string[] = ['Supprimer pour tous', 'Supprimer']
  toggleEmojiPicker() {
    console.log(this.showEmojiPicker);
    this.showEmojiPicker = !this.showEmojiPicker;
  }


  addEmoji(event) {
    console.log(this.message)
    const { message } = this;
    console.log(message);
    console.log(`${event.emoji.native}`)
    const text = `${message}${event.emoji.native}`;

    this.message = text;
    // this.showEmojiPicker = false;
  }


  onFocus() {
    console.log('focus');
    this.showEmojiPicker = false;
  }
  onBlur() {
    console.log('onblur')
  }
  constructor(private share: ShareService, private toastr: ToastrService, private userService: UserService,
    private tokenStorage: TokenStorageService, private modalService: NgbModal, private messageService: MessessageSignalService) { }


  ngOnInit() {
   
    this.interlocuteurs = []
    this.messageService.signalReceived.subscribe((data: any) => {
      console.log(data);

      this.getMyConversation();
    });
    /*  $(document).ready(function () {
      $(".fa-minus").click(function () {
        $(this).closest(".chatbox").toggleClass("chatbox-min");
      });
      $(".fa-close").click(function () {
        $(this).closest(".chatbox").hide();
      });
    }); */
    //  //alert(this.interlocuteurs.length)
    // console.log(this.users);


    // this.scrollSmoothToBottom ('holder')

    
    this.share.currentSocketReply.subscribe((data) => {
      this.getMySocketConversation(data.data.senderId);
    },
      (error) => {

      })

    this.share.currentdata.subscribe((e) => {
      console.log(e);
      //document.getElementById("chatbox").style.display = "block!important";
      if (e.length != 0) {


        this.interlocuteur = e[1];
        console.log(this.interlocuteur);
        this.interlocuteurs.forEach((element) => {
          if (element.id == this.interlocuteur.id) {
            this.find = true;

          }
        });
        if (this.find == false) {
          console.log(this.interlocuteur)
          this.receiver = this.interlocuteur.id
          this.userService.getMyconversation(this.tokenStorage.getUserInfos().id, this.receiver).subscribe((data) => {
            console.log('_____________sender______________');
            console.log(data.data)
            this.mess = data.data

            this.interlocuteurs.push(this.interlocuteur);
            this.userService.getMyconversation(this.receiver, this.tokenStorage.getUserInfos().id).subscribe((data: any) => {
              console.log('_____________receiver______________')
              console.log(data.data)
              for (let index = 0; index < data.data.length; index++) {
                this.mess.push(data.data[index]);

              }

              this.messages = this.mess.sort((a, b) => {
                let start: any = new Date(a.createdAt)
                let end: any = new Date(b.createdAt)
                return start - end;

              })
              //this.scrollSmoothToBottom ('holder')

            })
          })


        } else {
          this.reload()
          document.getElementById("boxushare").style.display = "flex";

        }

      }
    });
    // this.scrollSmoothToBottom ('holder')
    this.getMyConversation()
  }
  
  /***fonction pour recupere l ide de celui qui envoie le message */
  getSenderId(id, interlocuteurid, idmes) {
    this.messageSenderId = id
    this.interlocuteurId = interlocuteurid;
    this.idMessage = idmes
  }
  onFileSelected($event) {

    if ($event.target.files.length > 0) {
      this.fichiers = $event.target.files[0]
      console.log($event.target.files[0].name);
      this.nbrefile = 1;
    }
  }
  onRightClick() {
    this.displaymenu = true;
    this.display = false;

  }
  getmessageId(id, event) {
    // //alert(id)
    if (event.target.checked == true) {
      this.messageId.push(id)
    } else {
      const index = this.messageId.indexOf(id);
      if (index > -1) {
        this.messageId.splice(index, 1);
      }
    }

  }
  reload() {

    this.rload = true;
    this.getMyConversation()
  }
  public displayPostAvatarMiniature(uid: string): string {
    return this.url + "/account/readMiniature/" + uid;
  }
  minus() {
    document.getElementById('boxushare').style.height = "40px";

  }
  full() {
    document.getElementById('boxushare').style.height = "400px";
  }
  open(content) {
    this.modalService.open(content, { size: 'xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  getFile() {


    $("#upload:hidden").trigger('click');

  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  @HostListener('document:click')
  documentClick(): void {
    this.displaymenu = false;
    //this.display=true
  }
  handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
    /*  document.getElementById("picture").innerHTML="1"; */
    this.nbretof = 1
  }

  getMySocketConversation(mySenderId) {
    this.interlocuteurs.forEach((element) => {
      if (element.id == this.interlocuteur.id) {
        this.find = true;
      }
    });
    if (this.find == false) {
      this.receiver = this.interlocuteur.id;
      this.userService
        .getMyconversation(this.tokenStorage.getUserInfos().id, this.mySenderId)
        .subscribe((data) => {
          console.log("_____________sender______________");
          console.log(data.data);
          this.mess = data.data;

          this.interlocuteurs.push(this.interlocuteur);
          this.userService
            .getMyconversation(
              this.mySenderId,
              this.tokenStorage.getUserInfos().id
            )
            .subscribe((data: any) => {
              console.log("_____________receiver______________");
              console.log(data.data);
              for (let index = 0; index < data.data.length; index++) {
                this.mess.push(data.data[index]);
              }

              this.messages = this.mess.sort((a, b) => {
                let start: any = new Date(a.createdAt);
                let end: any = new Date(b.createdAt);
                return start - end;
              });
              this.load = false
              this.rload = false
            });
        });
    } else {
      this.receiver = this.interlocuteur.id;
      this.userService
        .getMyconversation(this.tokenStorage.getUserInfos().id, this.mySenderId)
        .subscribe((data) => {
          console.log("_____________sender______________");
          console.log(data.data);
          this.mess = data.data;

          //this.interlocuteurs.push(this.interlocuteur);
          this.userService
            .getMyconversation(
              this.mySenderId,
              this.tokenStorage.getUserInfos().id
            )
            .subscribe((data: any) => {
              console.log("_____________receiver______________");
              console.log(data.data);
              for (let index = 0; index < data.data.length; index++) {
                this.mess.push(data.data[index]);
              }

              this.messages = this.mess.sort((a, b) => {
                let start: any = new Date(a.createdAt);
                let end: any = new Date(b.createdAt);
                return start - end;
              });
              this.load = false
              this.rload = false
            });
        });
    }
  }

  getMyConversation() {

    this.interlocuteurs.forEach((element) => {
      if (element.id == this.interlocuteur.id) {
        this.find = true;
      }
    });
    if (this.find == false) {
      this.receiver = this.interlocuteur.id;
      this.userService
        .getMyconversation(this.tokenStorage.getUserInfos().id, this.receiver)
        .subscribe((data) => {
          console.log("_____________sender______________");
          console.log(data.data);
          this.mess = data.data;

          this.interlocuteurs.push(this.interlocuteur);
          this.userService
            .getMyconversation(
              this.receiver,
              this.tokenStorage.getUserInfos().id
            )
            .subscribe((data: any) => {
              console.log("_____________receiver______________");
              console.log(data.data);
              for (let index = 0; index < data.data.length; index++) {
                this.mess.push(data.data[index]);
              }

              this.messages = this.mess.sort((a, b) => {
                let start: any = new Date(a.createdAt);
                let end: any = new Date(b.createdAt);
                return start - end;
              });
              this.load = false
              this.rload = false
            });
        });
    } else {
      this.receiver = this.interlocuteur.id;
      this.userService
        .getMyconversation(this.tokenStorage.getUserInfos().id, this.receiver)
        .subscribe((data) => {
          console.log("_____________sender______________");
          console.log(data.data);
          this.mess = data.data;

          //this.interlocuteurs.push(this.interlocuteur);
          this.userService
            .getMyconversation(
              this.receiver,
              this.tokenStorage.getUserInfos().id
            )
            .subscribe((data: any) => {
              console.log("_____________receiver______________");
              console.log(data.data);
              for (let index = 0; index < data.data.length; index++) {
                this.mess.push(data.data[index]);
              }

              this.messages = this.mess.sort((a, b) => {
                let start: any = new Date(a.createdAt);
                let end: any = new Date(b.createdAt);
                return start - end;
              });
              this.load = false
              this.rload = false
            });
        });
    }
  }
  close(req) {
    document.getElementById("boxushare").style.display = "none";
    //$(this).closest(".chatbox").hide();
    const index = this.interlocuteurs.indexOf(req);
    if (index > -1) {
      this.interlocuteurs.splice(index, 1);
    }
    this.share.getChange([]);
  }


  _sendMessage() {
    this.load = true
    if (this.nbrefile > 0 || this.nbretof > 0) {
      var array: any = new FormData();
      array.append("fichier", this.fichiers)
      array.append("fichier", this.webcamImage)
      //this.userService._sendMessageWithfile(array,this.message,this.tokenStorage)
    } else {
      console.log(this.interlocuteur.conversationId);

      this.userService
        ._sendMessage(this.message, this.tokenStorage.getUserInfos().id, this.interlocuteur.id, this.interlocuteur.conversationId)
        .subscribe((data) => {
          console.log(data);
          this.message = ''
          this.getMyConversation();


        });
    }

  }


  _deleteMessage() {
    let tableLength = this.messageId.length
    switch (tableLength) {
      case 0:
        this.toastr.warning("Please select message to delete")
        break;
      case 1:
        this.userService.deleteMess(this.messageId[0]).subscribe((data: any) => {
          this.toastr.success("Messages deleted!");
          this.messageId = []
          this.reload()
        }, error => {
          this.toastr.error('Unabled to delete Mesages!')
        })
        break;
      default:
        this.userService.deleteMessage(this.messageId).subscribe((data: any) => {
          this.toastr.success("Messages deleted!");
          this.messageId = []
          this.reload()
        }, error => {
          this.toastr.error('Unabled to delete Mesages!')
        })
        break;
    }

  }
  turnUnseenToSeen() {

    // //alert('ok')
    this.userService
      .turnUnseenToSeen(this.messages[0].senderId, this.messages[0].receiverId)
      .subscribe((data) => {
        // //alert("seen")
      }, error => {
        // //alert('not ok')
      })
    this.userService
      .turnUnseenToSeen(this.messages[0].receiverId, this.messages[0].senderId,)
      .subscribe((data) => {
        // //alert("seen")
      }, error => {
        // //alert('not ok')
      })
  }
  scrollSmoothToBottom(id) {
    // //alert('ok')
    var div = document.getElementById(id);
    div.scrollTop = div.scrollHeight;
  }

  public parseToLocalDate(dateString: string): string {
    const date = new Date(dateString);
    const difference_In_Time = (new Date()).getTime() - date.getTime();
    const difference_In_Seconds = Math.floor(difference_In_Time / (1000)) + 1;
    if (difference_In_Seconds < 60)
      return difference_In_Seconds + ' second(s) ago';
    const difference_In_Minutes = Math.floor(difference_In_Time / (1000 * 60));
    if (difference_In_Minutes < 60)
      return difference_In_Minutes + ' min ago';
    const difference_In_Hours = Math.floor(difference_In_Time / (1000 * 60 * 60));
    if (difference_In_Hours < 24)
      return difference_In_Hours + ' hour(s) ago';
    const difference_In_Days = Math.floor(difference_In_Time / (1000 * 60 * 60 * 24));
    if (difference_In_Days < 30)
      return difference_In_Days + ' day(s) ago';
    const difference_In_Months = Math.floor(difference_In_Time / (1000 * 60 * 60 * 24 * 30));
    if (difference_In_Months < 365)
      return difference_In_Months + ' Month(s) ago';
    const difference_In_Years = Math.floor(difference_In_Time / (1000 * 60 * 60 * 24 * 30 * 365));
    if (difference_In_Years <= 365)
      return difference_In_Years + ' Years(s) ago';
    /* const options: any = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
    return date.toLocaleDateString('en-EN', options); */
  }

  public displayName(description: string): string {
    if (description.length < 20) {
      return description;
    } else {
      return description.substr(0, 20) + '...';
    }
  }
}

