import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Offer } from 'src/app/model/Offer';
import { OfferComment } from 'src/app/model/OfferComment';
import { Comment } from 'src/app/model/Comment';
import { CompanyService } from 'src/app/services/company.service';
import { NotifService } from 'src/app/services/notif.service';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../../auth/helpers/token-storage.service';

@Component({
  selector: 'app-display-comment',
  templateUrl: './display-comment.component.html',
  styleUrls: ['../module-style.css', './display-comment.component.css']
})
export class DisplayCommentComponent implements OnInit {

  // Déclaration d'un post
  @Input() comments: any[];
  // Déclaration du niveau d'un commentaire
  @Input() h: number;
  @Output()
  notifyOffer: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  notifyComment: EventEmitter<any> = new EventEmitter<any>();
  // Utilisateur connecté
  private currentUser: any;
  // Commentaire courrant
  private currentComment: string = '';
  // Commentaire courrant
  private currentCommentId: string = '';
  // Réponse au commentaire courrant
  private currentCommentReply: string = '';
  // L'utilisateur réponds à un commentaire
  private isReplying: boolean = false;
  // Url de la photo de profil
  photoProfil: string;
  url: string = environment.GATEWAY_API_URL;
  isSocial :boolean =false;

  constructor(
    private tokenStorageService: TokenStorageService,
    private companyService: CompanyService,
    private notifService: NotifService) { }

  ngOnInit(): void {
    this.init();
    // console.log("This are our comments: " + this.comments);
  }

  public init(): void {
    this.h++;
    this.currentUser = this.tokenStorageService.getUserInfos();
    if (this.currentUser) {

      if (this.currentUser.imageUrl !== '' && localStorage.getItem('social') === "yes") {
        this.photoProfil = this.currentUser.imageUrl;
        this.isSocial =true
        // alert(this.isSocial)
      }
      else if (!this.currentUser.avatar.uid ) {
        this.photoProfil = this.url + "/account/read/" + this.currentUser.avatar.uid;
        alert(this.currentUser.avatar.uid)
        console.log(this.photoProfil);
      }
    }

  }

  public addCommentLike(item: any): void {
    item.likes_nb++;
    item.liked = true;
  }

  public removeCommentLike(item: any): void {
    item.likes_nb--;
    item.liked = false;
  }

  public replyComment(id: any): void {
    this.startReplying(id);
    // this.focusTo('reply-form-' + id);
  }

  public startReplying(id: any): void {
    this.isReplying = true;
    this.currentCommentId = id;
  }

 /*  public getCommentNombre(post) {

    this.companyService.getCommentNumber(post.id).subscribe(
      (resp) => {
        post.commentsNb = resp.data;
      },
      (error) => {
      });
   }
 */

  public stopReplying(): void {
    this.isReplying = false;
    this.currentCommentId = '';
  }

  public onReplyComment(item: any): void {
    if (this.currentCommentReply.trim()) {
      // alert(item.id);
      this.stopReplying();
      this.computeComment(item);
      this.startReplying(item.id);
      //this.getCommentNombre(item);
    }
  }

  public clearCurrentCommentReply() {

    this.currentCommentReply = '';
  }

  public deleteComment(id: any): void {
    console.log("Trying to delete comment with id: " + id);
    /*for (var i = 0; i < this.comments.length; i++) {
      if(this.comments[i].id === id) {
        if(i === this.comments.length - 1) {
          this.comments.pop();
          console.log("Comment list 1: ", this.comments);
          return;
        }
        for (var j = i; j < this.comments.length - 1; j++) {
          this.comments[j] = this.comments[j + 1];
        }
        this.comments.pop();
        console.log("Comment list 2: ", this.comments);
        return;
      }

    }*/
    this.companyService.deleteComment(id).toPromise().then(resp => {
      console.log(resp);
      this.comments.splice(this.comments.findIndex(cmt => cmt.id === id), 1);
      this.notifService.success('Your comment have been successfully deleted!');
    }).catch(err => {
      console.log(err);
      this.notifService.danger('Server error, please check your network access!');
    });
    // console.log("Comment list 3: ", this.comments);
  }

  public checkIfIsCurrentReplyZone(id: any): boolean {
    return (id === this.currentCommentId && this.isReplying);
  }

  public computeComment(item: any): void {
    const comment: any = {
      parentId: item.id,
      message: this.currentCommentReply,
      user: this.currentUser,
      offer: item.offer,
      commentsNb: 0,
      likesNb: 0,
      liked: false,
      comments: []
    };
    this.companyService.createComment(comment, item.offer.id, this.currentUser.id).subscribe(
      res => {
        //alert('ok koo')
        this.notifService.success('Comment add');
        //this.init()
        item.commentsNb = (res.data as OfferComment).commentsNb;
        comment.id = (res.data as OfferComment).id;
        comment.offer = (res.data as OfferComment).offer;
        if (item.comments) {
          console.log(comment);

          item.comments.push(comment);
        } else {
          item.comments = [];
          item.comments.push(comment);
        }
        this.sendOffer((res.data as OfferComment).offer);
        this.currentCommentReply = '';
        //this.getCommentNombre(item);
        this.sendComment(comment);
      }, error => {
        console.log(error);
        this.notifService.danger('Server error, please check your network access!');
      }
    );
  }

  public setOfferCmtsNbr(comment: Comment): void {
    this.companyService.notifyOffer(comment);
  }

  public updateOfferComments(post: Offer, comment: Comment, parent: Comment): Offer {
    if (comment.parentId == null) {
      post.commentsNb = comment.offer.commentsNb;
      post.comments.push(comment);
      return post;
    } else if (comment.parentId == parent.id) {
      post.commentsNb = comment.offer.commentsNb;
      parent.comments.push(comment);
      parent.commentsNb = parent.comments.length;
      return post;
    } else {
      let n = parent.comments != null ? parent.comments.length : 0;
      if (n > 0) {
        for (let index = 0; index < n; index++) {
          this.updateOfferComments(post, comment, parent.comments[index]);
        }
      }
    }
  }

  public generateId(): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 20; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public computeZoneId(prefix: string, id: any): string {
    return prefix + id;
  }

  public displayLastPostTime(postDate: Date): string {
    const difference_In_Time = (new Date()).getTime() - postDate.getTime();
    const difference_In_Seconds = Math.floor(difference_In_Time / (1000)) + 1;
    if (difference_In_Seconds < 60)
      return difference_In_Seconds + ' second(s) ago';
    const difference_In_Minutes = Math.floor(difference_In_Time / (1000 * 60));
    if (difference_In_Minutes < 60)
      return difference_In_Minutes + ' min ago';
    const difference_In_Hours = Math.floor(difference_In_Time / (1000 * 60 * 60));
    if (difference_In_Hours < 24)
      return difference_In_Hours + ' hour(s) ago';
    const difference_In_Days = Math.floor(difference_In_Time / (1000 * 60 * 60 * 24));
    if (difference_In_Days < 30)
      return difference_In_Days + ' day(s) ago';
    const difference_In_Months = Math.floor(difference_In_Time / (1000 * 60 * 60 * 24 * 30));
    if (difference_In_Months < 365)
      return difference_In_Months + ' Month(s) ago';
    const difference_In_Years = Math.floor(difference_In_Time / (1000 * 60 * 60 * 24 * 30 * 365));
    if (difference_In_Years <= 365)
      return difference_In_Years + ' Years(s) ago';
  }

  public focusTo(id: string): void {
    console.log('Trying to focus to the zone with id ' + id);
    document.getElementById(id + "").focus();
  }

  public displayPostAvatar(uid: string): string {
    // --- console.log("uid here " + uid)
    //alert(uid)
   // console.log(uid);

    return this.url + "/account/read/" + uid;
  }

  public parseToLocalDate(dateString: string): string {
    const date =  new Date(dateString);
    const difference_In_Time = (new Date()).getTime() - date.getTime();
    const difference_In_Seconds = Math.floor(difference_In_Time / (1000)) + 1;
    if (difference_In_Seconds < 60)
      return difference_In_Seconds + ' second(s) ago';
    const difference_In_Minutes = Math.floor(difference_In_Time / (1000 * 60));
    if (difference_In_Minutes < 60)
      return difference_In_Minutes + ' min ago';
    const difference_In_Hours = Math.floor(difference_In_Time / (1000 * 60 * 60));
    if (difference_In_Hours < 24)
      return difference_In_Hours + ' hour(s) ago';
    const difference_In_Days = Math.floor(difference_In_Time / (1000 * 60 * 60 * 24));
    if (difference_In_Days < 30)
      return difference_In_Days + ' day(s) ago';
    const difference_In_Months = Math.floor(difference_In_Time / (1000 * 60 * 60 * 24 * 30));
    if (difference_In_Months < 365)
      return difference_In_Months + ' Month(s) ago';
    const difference_In_Years = Math.floor(difference_In_Time / (1000 * 60 * 60 * 24 * 30 * 365));
    if (difference_In_Years <= 365)
      return difference_In_Years + ' Years(s) ago';
    /* const options: any = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
    return date.toLocaleDateString('en-EN', options); */
  }

  public sendOffer(offer: any): void {
    this.notifyOffer.emit(offer);
  }

  public sendComment(comment: any): void {
    this.notifyComment.emit(comment);
  }

}
