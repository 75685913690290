import { User } from './user';
import { Offer } from './Offer';
export class Comment {
  id: string;
  // parentId est l'identifant racine du commentaire qui sera trouvé dans la reponse au commentaire en question
  parentId: string;
  version:string;
  message: string;
  offer: Offer;
  user: User;
  createdAt: Date;
  likesNb: number;
  commentsNb: number;
  comments: Comment[];
}
